import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  users: { sortField: "" },
  userGroup: { sortField: "" },
  images: { sortField: "" },
  imageGroup: { sortField: "" },
};

export const sortSlice = createSlice({
  name: "sort",
  initialState,
  reducers: {
    setSortField: (state, action) => {
      let { key, field } = action.payload;
      state[key].sortField = field;
    },
  },
});

export const { setSortField } = sortSlice.actions;

export default sortSlice.reducer;
