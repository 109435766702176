import { createSlice, current, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initOffers = (offers) => {
  return offers?.map((member) => {
    return { ...member, checked: false };
  });
};

const initialState = {
  offers: [],
  searchItems: [],
  meta: {},
  offer: {},
  state: "idle",
  error: null,
  message: null,
  loading: null,
  activePlan: null,
  searchString: ""
};

//get offers
export const getOffers = createAsyncThunk("api/offers", async ({ query, orgId }) => {
  let data;
  let url = orgId ? `/orgs/${orgId}/plans` : `/plans/operator`;
  try {
    const response = await axios.get(`${url}`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const getActivePlan = createAsyncThunk(
  "api/current-plan",
  async ({ orgId, subscriptionId }) => {
    let data;
    try {
      const response = await axios.get(`/subscriptions/${orgId}/${subscriptionId}`);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const createOffer = createAsyncThunk("api/create-offer", async (values, thunkApi) => {
  let data;
  try {
    const response = await axios.post(`/plans/operator`, { ...values });
    data = await response.data;
    if (response.status === 200) {
      thunkApi.dispatch(getOffers({ query: "" }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
});
// // delete offer
// export const deleteOffer = createAsyncThunk("api/delete-offer", async (id, thunkApi) => {
//   let data;
//   try {
//     const response = await axios.delete(`/operator/offers/${id}`);
//     data = await response.data;
//     if (response.status === 200) {
//       thunkApi.dispatch(getOffers({ query: "" }));
//       return data;
//     }
//     throw new Error(response.statusText);
//   } catch (err) {
//     const error = err;
//     return Promise.reject(error.message ? error.message : error.error);
//   }
// });

// get one offer
// export const getOffer = createAsyncThunk("api/get-of", async (id) => {
//   let data;
//   try {
//     const response = await axios.get(`/operator/offers/${id}`);
//     data = await response.data;
//     if (response.status === 200) {
//       return data;
//     }
//     throw new Error(response.statusText);
//   } catch (err) {
//     const error = err;
//     return Promise.reject(error.message ? error.message : error.error);
//   }
// });

// edit offer
export const updateOffer = createAsyncThunk("api/edit-of", async (query, thunkApi) => {
  let data;
  let { values, id } = query;
  try {
    const response = await axios.put(`/plans/operator/${id}`, { ...values });
    data = await response.data;

    if (response.status === 200) {
      setTimeout(() => {
        thunkApi.dispatch(getOffers({ query: "" }));
      }, 500);
      return data;
    }
    // throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error);
  }
});

export const pricingSlice = createSlice({
  name: "pricing",
  initialState,
  reducers: {
    selectAll: (state, action) => {
      state.offers = current(state).offers.map((member) => {
        return { ...member, checked: action.payload };
      });
    },
    selectById: (state, action) => {
      state.offers = current(state).offers.map((member) => {
        if (member.id === action.payload.id) return { ...member, checked: action.payload.value };
        return member;
      });
    },
    setOffers: (state, action) => {
      state.offers = action.payload;
    },
    setSearchString: (state, action) => {
      state.searchString = action.payload;
    }
  },
  extraReducers: {
    //get offers
    [getOffers.pending]: (state) => {
      state.error = null;
      state.state = "loading";
      state.loading = true;
    },
    [getOffers.fulfilled]: (state, action) => {
      const offers = action.payload?.Plans;
      const newData = initOffers(offers);
      state.offers = newData;
      state.searchItems = newData;
      state.meta = action.payload.meta;
      state.state = "success";
      state.loading = null;
    },
    [getOffers.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
      state.loading = null;
    },
    [getActivePlan.pending]: (state) => {
      state.error = null;
      state.state = "loading";
      state.loading = true;
    },
    [getActivePlan.fulfilled]: (state, action) => {
      state.activePlan = action.payload?.plan;
      state.state = "success";
      state.loading = null;
    },
    [getActivePlan.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
      state.activePlan = null;
      state.loading = null;
    },

    //create offer
    [createOffer.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [createOffer.fulfilled]: (state, action) => {
      state.state = "success";
    },
    [createOffer.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    }
    //get offer
    // [getOffer.pending]: (state) => {
    //   state.error = null;
    //   state.state = "loading";
    // },
    // [getOffer.fulfilled]: (state, action) => {
    //   const offer = action.payload;
    //   state.state = "success";
    //   state.offer = offer;
    // },
    // [getOffer.rejected]: (state, action) => {
    //   state.error = action.error.message;
    //   state.state = "error";
    // }
  }
});

export const { selectAll, selectById, setOffers, setSearchString } = pricingSlice.actions;

export default pricingSlice.reducer;
