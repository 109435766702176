import { Switch } from "antd";
import React from "react";
import "./_BooleanFeature.scss";

function BooleanFeature({ title, icon, formik, checked, onChange }) {
  return (
    <div className="ldap_checkbox">
      <div className="title_item">
        <img src={icon} alt="" />
        <p> {title}</p>
      </div>
      <div className="switch">
        <Switch checked={checked} onChange={onChange}></Switch>
        <p className={checked ? "status-active" : "status-inactive"}>
          {checked ? "Active" : "Inactive"}
        </p>
      </div>
    </div>
  );
}

export default BooleanFeature;
