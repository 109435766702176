import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { message, Modal, Switch } from "antd";
import { CREATE_OFFER_FIELDS as fields } from "../../../UserGroup/constants/fields";
import { InputText } from "../../../../components/InputFields";
import UploadFile from "../upload/UploadFile";
import "./_CreateOffer.scss";
import { createOffer, updateOffer } from "../../../../data/slices/plans";
import { getOrgs } from "../../../../data/slices/organizations";
import { useLocation } from "react-router-dom";
import PlanFeaturesForm from "../PlanFeaturesForm/PlanFeaturesForm";
import { convertValuesToStrings } from "../../../../helpers/ convertValuesToString";
import PlanVisibility from "../PlanVisibility/PlanVisibility";
import AllowedTemplates from "../AllowedTemplates/AllowedTemplates";
let cpu_p = 0.028;
let ram_p = 0.007;

function CreateOffer({ id, open, handleClose, data = null, ...rest }) {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [isPublic, setIsPublic] = useState(true);
  const { organizations } = useSelector((state) => state.organization);
  const formik = useFormik({
    initialValues: {
      active: true,
      name: "",
      description: "",
      unit_amount: "",
      images: [],
      allowedTemplates: ["*"],
      metadata: {
        cpu: "",
        ram: "",
        storage: "",
        orgId: "",
        max_users: "",
        ldap: false,
        canPersistStorage: false
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required field"),
      unit_amount: Yup.number().required("Amount is required"),
      metadata:
        !data?.isEdit &&
        Yup.object().shape({
          cpu: Yup.number().positive("CPU must be a positive number").required("CPU is required"),
          ram: Yup.number().positive("RAM must be a positive number").required("RAM is required"),
          storage: Yup.number()
            .positive("Storage must be a positive number")
            .required("Storage is required"),
          max_users: Yup.number()
            .positive("Number of users must be a positive number")
            .required("Number of users is required")
        })
    }),
    onSubmit: (values) => {
      // images validations
      if (values.images.length === 0) {
        message.error("Plan Image is Required");
        return;
      }
      // org validations
      if (!isPublic) {
        if (!values.metadata.orgId) {
          message.error("Organization is required");
          return;
        }
      }

      const metadata = {
        ...convertValuesToStrings(values.metadata),
        allowedTemplates: values.allowedTemplates
      };

      values.unit_amount = Math.ceil(values.unit_amount);

      if (data?.isEdit === false) {
        dispatch(
          createOffer({
            ...values,
            metadata
          })
        )
          .unwrap()
          .then((res) => {
            if (res?.ok === true) {
              message.success("Plan Created Successfully");
              formik.resetForm();
              handleClose(id);
            } else {
              message.error("Something went wrong");
            }
          })
          .catch((error) => {
            console.log(error);
            message.error("Something went wrong");
          });
      } else {
        // if (Object.keys(values.metadata).length === 0) {
        //   message.error("cpu and hours are required");
        //   return;
        // }

        dispatch(
          updateOffer({
            values: {
              ...values,
              metadata
            },
            id: data?.id
          })
        )
          .unwrap()
          .then((res) => {
            if (res?.ok === true) {
              message.success("Plan Updated Successfully");
              formik.resetForm();
              handleClose(id);
            } else {
              message.error("Something went wrong");
            }
          })
          .catch((error) => {
            console.log(error);
            message.error("Something went wrong");
          });
      }
    }
  });
  //set values for edit
  useEffect(() => {
    if (data?.isEdit) {
      formik.setFieldValue("images", data?.plan?.spec?.images);
      formik.setFieldValue(
        "allowedTemplates",
        JSON.parse(data?.plan?.spec?.metadata?.allowedTemplates)
      );
      formik.setFieldValue("name", data?.plan?.spec?.name);
      formik.setFieldValue("description", data?.plan?.spec?.description);
      formik.setFieldValue("metadata", data?.plan?.spec?.metadata);
      formik.setFieldValue("metadata.orgId", data?.plan?.metadata?.labels?.orgId);
      formik.setFieldValue("active", data?.plan?.spec?.active);
      formik.setFieldValue("unit_amount", +data?.plan?.spec?.default_price?.spec?.unit_amount || 0);
      setIsPublic(data?.plan?.metadata?.labels?.isPublic === "true" ? true : false);
    }
  }, [data, data?.isEdit]);

  useEffect(() => {
    if (organizations?.length === 0 && open) {
      dispatch(getOrgs({ query: "" }));
    }
    if (state?.id) {
      formik.setFieldValue("metadata.orgId", state.id);
      if (!data?.isEdit) {
        setIsPublic(false);
      }
    }
  }, [state, data]);

  const autoCalculatePrice = (cpu, ram) => {
    let price = 0;
    price = cpu * cpu_p + ram * ram_p;
    return price.toFixed(3);
  };

  useEffect(() => {
    let price = autoCalculatePrice(formik.values.metadata.cpu, formik.values.metadata.ram);
    if (data?.isEdit) {
    }
    formik.setFieldValue("unit_amount", price);
  }, [formik.values.metadata.cpu, formik.values.metadata.ram]);

  return (
    <Modal
      open={open}
      onCancel={() => {
        handleClose(id);
        formik.resetForm();
      }}
      style={{
        top: 10
      }}
      onOk={formik.handleSubmit}
      okText={data?.isEdit ? "Edit Plan" : "Publish"}
      title={data?.isEdit ? "Edit Plan" : "Create Plan"}
      className="plan-modal"
      maskClosable={false}
    >
      <form action="">
        <div className="offer_form">
          <div className="grid_line">
            <div className="upload fl">
              offer Image
              <UploadFile
                formik={formik}
                field="images"
                isEdit={data?.isEdit}
                defaultFiles={data?.isEdit ? data?.plan?.spec?.images : []}
              />
            </div>
            <InputText formik={formik} field={fields[0]} />
          </div>
          <InputText formik={formik} field={fields[1]} />

          <PlanFeaturesForm formik={formik} cpu_p={cpu_p} ram_p={ram_p} />
          <PlanVisibility formik={formik} isPublic={isPublic} setIsPublic={setIsPublic} />
          <AllowedTemplates formik={formik} />
        </div>
        <div className="price_item_total">
          <span className="unit">Total Amount $ </span>
          {/* <p className="price">
            ${autoCalculatePrice(formik.values.metadata.cpu, formik.values.metadata.ram)}
          </p> */}
          <div className="price">
            <InputText
              formik={formik}
              field={{
                name: "unit_amount",
                type: "number",
                placeholder: "Amount"
              }}
              value={formik.values.unit_amount}
              status={formik.errors.unit_amount && formik.touched.unit_amount ? "error" : null}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default CreateOffer;
