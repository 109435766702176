import { createSlice, current, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initOrgs = (ORGS) => {
  return ORGS.map((member) => {
    return { ...member, checked: false };
  });
};

const initialState = {
  organizations: [],
  searchItems: [],
  meta: {},
  org: {},
  state: "idle",
  error: null,
  message: null,
  loading: null,
  searchString: ""
};

//get orgs
export const getOrgs = createAsyncThunk("api/orgs", async ({ query }) => {
  let data;
  // let str = page && limit && search ? `?search=${search}page=${page}&limit=${limit}` : "";
  try {
    const response = await axios.get(`/orgs${query}`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const createOrg = createAsyncThunk("api/crate-org", async (values, thunkApi) => {
  let data;
  try {
    const response = await axios.post(`/orgs`, { ...values });
    data = await response.data;
    if (response.status === 200) {
      thunkApi.dispatch(getOrgs({ query: "?page=1&limit=8" }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;

    return Promise.reject(error.message ? error.message : error.error);
  }
});
// delete org
export const deleteOrg = createAsyncThunk("api/delete-org", async (id, thunkApi) => {
  let data;
  try {
    const response = await axios.delete(`/orgs/${id}`);
    data = await response.data;
    if (response.status === 200) {
      thunkApi.dispatch(getOrgs({ query: "?page=1&limit=8" }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : error.error);
  }
});

// get one org
export const getOrg = createAsyncThunk("api/get-org", async (id) => {
  let data;
  try {
    const response = await axios.get(`/orgs/${id}`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : error.error);
  }
});

// edit user
export const updateOrg = createAsyncThunk("api/edit-org", async (query, thunkApi) => {
  let data;
  let { values, id } = query;
  try {
    const response = await axios.put(`/orgs/${id}`, { ...values });
    data = await response.data;

    if (response.status === 200) {
      thunkApi.dispatch(getOrgs({ query: "?page=1&limit=8" }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : error.error);
  }
});

export const memberSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    selectAll: (state, action) => {
      state.organizations = current(state).organizations.map((member) => {
        return { ...member, checked: action.payload };
      });
    },
    selectById: (state, action) => {
      state.organizations = current(state).organizations.map((member) => {
        if (member.id === action.payload.id) return { ...member, checked: action.payload.value };
        return member;
      });
    },
    setUsers: (state, action) => {
      state.organizations = action.payload;
    },
    setSearchString: (state, action) => {
      state.searchString = action.payload;
    }
  },
  extraReducers: {
    //get users
    [getOrgs.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [getOrgs.fulfilled]: (state, action) => {
      const orgs = action.payload.organizations;
      const newData = initOrgs(orgs);
      state.organizations = newData;
      state.searchItems = newData;
      state.meta = action.payload.meta;
      state.state = "success";
    },
    [getOrgs.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },

    //create org
    [createOrg.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [createOrg.fulfilled]: (state, action) => {
      const user = action.payload;
      state.state = "success";
    },
    [createOrg.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    //get org
    [getOrg.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [getOrg.fulfilled]: (state, action) => {
      const org = action.payload;
      state.state = "success";
      state.org = org;
    },
    [getOrg.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    }
  }
});

export const { selectAll, selectById, setUsers, setSearchString } = memberSlice.actions;

export default memberSlice.reducer;
