import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import ModalsProvider from "./components/ModalsProvider";
import NotFoundView from "./components/NotFound";
import GuestLayout from "./layouts/GuestLayout";
import AdminLayout from "./layouts/AdminLayout";
import { lazy } from "react";
import { Suspense } from "react";
import DrawersProvider from "./components/DrawersProvider";
import GetPermissions from "./context/permissions/GetPermissions";
import { AbilityContext } from "./context/permissions/Can";
import { useSelector } from "react-redux";
import Aos from "aos";
import "aos/dist/aos.css";
import SplashScreen from "./components/SplashScreen/Splashscreen";

const RoutesProvider = () => {
  Aos.init({ duration: 2000 });

  const LoginView = lazy(() => import("./views/Login"));
  const RegisterView = lazy(() => import("./views/Register"));
  const ResetPasswordView = lazy(() => import("./views/ResetPassword"));
  const ForgetPasswordView = lazy(() => import("./views/ForgetPassword"));
  const UserGroup = lazy(() => import("./views/UserGroup"));
  const ImageGroup = lazy(() => import("./views/ImageGroup"));
  const OrgView = lazy(() => import("./views/Organizations"));
  const OperatorsView = lazy(() => import("./views/UsersPerOrg"));
  const DashboardView = lazy(() => import("./views/Dashboard"));
  const OffersView = lazy(() => import("./views/Offers"));
  const Settings = lazy(() => import("./views/Profile"));
  const ActiveSessions = lazy(() => import("./views/ActiveSessions"));
  const Users = lazy(() => import("./views/Users"));

  const auth = useSelector((state) => state?.auth);
  const listOfPermissions = GetPermissions(auth?.user?.roles);

  return (
    <AbilityContext.Provider value={listOfPermissions}>
      <BrowserRouter>
        <Suspense fallback={<SplashScreen />}>
          <Routes>
            {/* Guests Routes */}
            <Route path="/auth" element={<GuestLayout />}>
              <Route index element={<Navigate to="login" />} />
              <Route path="login" element={<LoginView />} />
              <Route path="register" element={<RegisterView />} />
              <Route path="reset-password" element={<ResetPasswordView />} />
              <Route path="forget-password" element={<ForgetPasswordView />} />
            </Route>

            {/* Admin Routes */}
            <Route path="/" element={<AdminLayout />}>
              <Route index element={<DashboardView />} />
              <Route path="organizations/users" element={<OperatorsView />} />
              <Route path="images" element={<ImageGroup />} />
              <Route path="organizations" element={<OrgView />} />
              {/* <Route path="operators" element={<UserGroup />} /> */}
              <Route path="user-groups" element={<UserGroup />} />
              <Route path="users" element={<Users />} />
              <Route path="plans" element={<OffersView />} />
              <Route path="settings" element={<Settings />} />
              <Route path="/active-sessions" element={<ActiveSessions />} />

              {/* <Route path="billing" element={<BillingPage />} /> */}
            </Route>
            {/* Uncreated Routes */}
            <Route path="*" element={<NotFoundView />} />
          </Routes>
          <ModalsProvider />
          <DrawersProvider />
        </Suspense>
      </BrowserRouter>
    </AbilityContext.Provider>
  );
};

export default RoutesProvider;
