import React, { useEffect } from "react";
import "./_MonthlyYearlyPrice.scss";
import { InputText } from "../../../../components/InputFields";
import { IoIosHelpCircleOutline } from "react-icons/io";
import BooleanFeature from "../BooleanFeature/BooleanFeature";

function MonthlyYearlyPrice({ isFreePlan, setIsFreePlan, formik }) {
  useEffect(() => {
    if (isFreePlan) {
      formik.setFieldValue("prices", [
        {
          amount: 0,
          recurringInterval: "day",
          recurringIntervalCount: null
        }
      ]);
    } else {
      formik.setFieldValue("prices", [
        {
          amount: null,
          recurringInterval: "month",
          recurringIntervalCount: 1
        },
        {
          amount: null,
          recurringInterval: "year",
          recurringIntervalCount: 1
        }
      ]);
    }
  }, [isFreePlan]);

  return (
    <div className="monthly-yearly-price">
      <div className="switch-free-plan">
        <BooleanFeature
          title="Free Plan"
          icon={""}
          checked={isFreePlan}
          onChange={(e) => setIsFreePlan(e)}
        />
      </div>
      {!isFreePlan && (
        <div className="flex-fields">
          <span className="f-monthly-price-field">
            <InputText
              formik={formik}
              field={{
                name: "prices[0].amount",
                type: "number",
                label: "Monthly Price"
              }}
              value={formik.values.prices[0].amount}
              status={
                formik.errors?.prices?.[0]?.amount && formik.touched?.prices?.[0]?.amount
                  ? "error"
                  : null
              }
            />
            <p className="error_txt">
              {formik.touched?.prices?.[0]?.amount && formik.errors?.prices?.[0]?.amount}
            </p>
          </span>

          <span className="f-monthly-price-field">
            <InputText
              formik={formik}
              field={{
                name: "prices[1].amount",
                type: "number",

                label: "Yearly Price"
              }}
              value={formik.values?.prices[1]?.amount}
              status={
                formik?.errors?.prices?.[1]?.amount && formik.touched?.prices?.[1]?.amount
                  ? "error"
                  : null
              }
            />
            <p className="error_txt">
              {formik.touched?.prices?.[1]?.amount && formik.errors?.prices?.[1]?.amount}
            </p>
          </span>
        </div>
      )}
      {isFreePlan && (
        <div className="free-plan-price">
          <div className="daily-price">
            <div className="amount-daily">
              <InputText
                formik={formik}
                field={{
                  name: "prices[0].amount",
                  type: "number",
                  label: "Amount"
                }}
                value={"0"}
                disabled={true}
              />
            </div>
            <label htmlFor="">Billing Period</label>
            <div className="every-period">
              <span className="every">Every</span>
              <InputText
                formik={formik}
                field={{
                  name: "prices[0].recurringIntervalCount",
                  type: "number"
                }}
                value={isFreePlan && formik.values.prices[0].recurringIntervalCount}
              />
              <span className="every">Days</span>
            </div>
            <p className="error_txt">
              {formik.touched?.prices?.[0]?.recurringIntervalCount &&
                formik.errors?.prices?.[0]?.recurringIntervalCount}
            </p>
          </div>
        </div>
      )}
      <div className="help-message">
        <IoIosHelpCircleOutline size={17} /> <p>Amount must be 0 or greater than or equal to 0.5</p>
      </div>
    </div>
  );
}

export default MonthlyYearlyPrice;
