import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import closeIcon from "../../../../../assets/icons/dashboard-x-close.svg";
import React from "react";
import ImageForm from "../../ImageForm/ImageForm";

function ImageModal({ id, open, handleClose, data, ...rest }) {
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {}}
      className="ta-modal dashboard-user-modal"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "829px"
          }
        }
      }}
    >
      <DialogTitle id="alert-dialog-title" className="modal_title">
        <span className="label">{data?.isEdit ? "Edit Image" : "Create New Image"}</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          <img src={closeIcon} alt="" />
        </span>
      </DialogTitle>
      <p className="subtitle">
        {data?.isEdit
          ? "Add all the information in order to update your image"
          : "Add all the information in order to create your new image"}
      </p>

      <DialogContent className="ta-modal-content-scroll">
        <ImageForm
          isEdit={data?.isEdit || false}
          id={data?.isEdit ? data?.id : null}
          image={data?.isEdit ? data?.image : null}
        />
      </DialogContent>
    </Dialog>
  );
}

export default ImageModal;
