import axios from "axios";

export const uploadFile = async (file, additionalFields) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    for (const key in additionalFields) {
      if (additionalFields.hasOwnProperty(key)) {
        formData.append(key, additionalFields[key]);
      }
    }

    const response = await axios.post(
      "https://api.cloudinary.com/v1_1/dj6kxvxqb/image/upload",
      formData
    );

    if (response.data && response.data.secure_url) {
      return response.data.secure_url;
    } else {
      throw new Error("Failed to upload file to Cloudinary.");
    }
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};
