import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import closeIcon from "../../../assets/icons/dashboard-x-close.svg";
import deleteIcon from "../../../assets/icons/dashboard-modal-delete.svg";
import { closeModal } from "../../../data/slices/modals";
import { useDispatch } from "react-redux";
import { deleteUser } from "../../../data/slices/memberSlice";
import { message } from "antd";
import { deleteGroup } from "../../../data/slices/userGroup";
import { deleteImageGroup } from "../../../data/slices/imageGroup";
import { deleteImage } from "../../../data/slices/templates";
import { deleteSession } from "../../../data/slices/sessions";

function ModalConfirm({ id, open, handleClose, data, ...rest }) {
  const dispatch = useDispatch();
  const handleConfirm = () => {
    if (data.type === "delete_user") {
      if (data.id) {
        dispatch(deleteUser({ id: data.id, orgId: data?.orgId }))
          .unwrap()
          .then((res) => {
            message.success(`User deleted successfully.`);
            dispatch(closeModal("confirm-modal"));
          })
          .catch((err) => {
            message.error(err.message || "something went wrong");
          });
      }
    } else if (data.type === "delete_user_group") {
      if (data.id) {
        dispatch(deleteGroup(data.id))
          .unwrap()
          .then((res) => {
            message.success(`Group deleted successfully.`);
            dispatch(closeModal("confirm-modal"));
          })
          .catch((err) => {
            message.error(err.message || "something went wrong");
          });
      }
    } else if (data.type === "delete_image_group") {
      if (data.id) {
        dispatch(deleteImageGroup(data.id))
          .unwrap()
          .then((res) => {
            message.success(`Image Group deleted successfully.`);
            dispatch(closeModal("confirm-modal"));
          })
          .catch((err) => {
            message.error(err.message || "something went wrong");
          });
      }
    } else if (data.type === "delete_image") {
      if (data.id) {
        dispatch(deleteImage(data.id))
          .unwrap()
          .then((res) => {
            message.success(`Image deleted successfully.`);
            dispatch(closeModal("confirm-modal"));
          })
          .catch((err) => {
            message.error(err.message || "something went wrong");
          });
      }
    } else if (data.type === "delete_active_session") {
      console.log({ data });

      dispatch(deleteSession({ name: data?.data?.name, namespace: data?.data?.namespace }))
        .unwrap()
        .then((res) => {
          message.success(`Session deleted successfully.`);
          dispatch(closeModal("confirm-modal"));
        })
        .catch((err) => {
          message.error(err.message || "something went wrong");
        });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason === "backdropClick" && reason !== "escapeKeyDown") {
          handleClose(id);
        }
      }}
      className="ta-modal confirm_modal"
    >
      <DialogTitle id="alert-dialog-title" className="modal_title">
        <img src={deleteIcon} alt="" />
        <span className="close-btn" onClick={() => handleClose(id)}>
          <img src={closeIcon} alt="" />
        </span>
      </DialogTitle>

      <DialogContent className="ta-modal-content-scroll content_confirm">
        <p className="title">{data?.title}</p>
        <div className="subtitle-container">
          <p className="subtitle">{data?.subTitle}</p>
        </div>
      </DialogContent>
      <div className="action_confirm">
        <button
          onClick={() => dispatch(closeModal("confirm-modal"))}
          className="dashboard-cancel-btn"
        >
          Cancel
        </button>
        <button onClick={handleConfirm} className="dashboard-confirm-btn">
          Confirm
        </button>
      </div>
    </Dialog>
  );
}

export default ModalConfirm;
