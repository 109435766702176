import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import imageIcon from "./../../../../assets/icons/procreate.svg";
import selectedIcon from "./../../../../assets/icons/dashboard-image-selected.svg";
import { InputSelect, InputText, InputTextArea } from "../../../../components/InputFields";
import { closeModal } from "../../../../data/slices/modals";
import { useDispatch, useSelector } from "react-redux";
import InputSearch from "../../../../components/InputFields/InputSearch/InputSearch";
import { useState } from "react";
import { Checkbox, message } from "antd";
import { createImageGroup, updateImageGroup } from "../../../../data/slices/imageGroup";
import { getOrgs } from "../../../../data/slices/organizations";

export const setImagesToForm = (formik, image, allowedTemplates = "images") => {
  let images = formik.values[allowedTemplates] || [];
  const imageIndex = images?.findIndex((img) => img === image?.name);
  if (imageIndex !== -1) images = images?.filter((_, i) => i !== imageIndex);
  else images = [...images, image?.name];
  formik.setFieldValue(allowedTemplates, images);
};

const imageGroupFormValidation = Yup.object().shape({
  name: Yup.string().min(2, "Too Short!").max(70, "Too Long!").required("This field is required"),
  description: Yup.string().min(2, "Too Short!").max(100, "Too Long!"),
  thumbnail: Yup.string()
});

function ImageGroupForm({ isEdit, id, group }) {
  const { templates } = useSelector((state) => state.templates);
  const { organizations } = useSelector((state) => state.organization);

  const [images, setImages] = useState(templates?.data);
  const onSearch = (value) => {
    setImages(templates?.data?.filter((image) => image?.name?.includes(value?.target?.value)));
  };
  useEffect(() => {
    dispatch(getOrgs({ query: "" }));
    // formik.setFieldValue("orgId", orgId);
  }, []);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: isEdit ? group?.displayName : "",
      description: isEdit ? group?.description : "",
      images: isEdit ? group?.images : []
    },
    validationSchema: imageGroupFormValidation,
    onSubmit: (values) => {
      console.log({ values });
      if (!values?.images || values?.images?.length === 0) {
        message.error("You Have To Check One Image At Least");
        return;
      }
      if (!isEdit) {
        dispatch(createImageGroup({ ...values }))
          .unwrap()
          .then((res) => {
            message.success(`Image Groups created successfully.`);
            dispatch(closeModal("image-group"));
          })
          .catch((err) => {
            message.error(err.message || "something went wrong");
          });
      } else {
        dispatch(
          updateImageGroup({
            values,
            id
          })
        )
          .unwrap()
          .then(() => {
            message.success(`Image Groups updated successfully.`);
            dispatch(closeModal("image-group"));
          })
          .catch((err) => {
            message.error(err.message || "something went wrong");
          });
      }
    }
  });
  // TODO : fix org select when create and delete
  return (
    <div className="image_group_form form">
      <form action="" onSubmit={formik.handleSubmit}>
        <InputText
          formik={formik}
          field={{
            name: "name",
            placeholder: "Enter the fiels name",
            label: "Name"
          }}
        />
        <InputSelect
          label="Organization"
          single={false}
          formik={formik}
          options={organizations?.map((item) => ({
            value: item.metadata?.name,
            label: item.spec?.name
          }))}
          // defaultOptions={orgId || null}
          onChange={(e) => {
            formik.setFieldValue("orgId", e);
          }}
          field="orgs"
          onDropdownVisibleChange={() => {
            dispatch(getOrgs({ query: "" }));
          }}
          disabled={isEdit ? true : false}
        ></InputSelect>
        <InputTextArea
          formik={formik}
          field={{
            name: "description",
            placeholder: "Write a brief description...",
            label: "Description"
          }}
        />

        <InputSearch
          formik={formik}
          field={{
            name: "images",
            label: "Add Images To Group",
            placeholder: "Search for images here",
            type: "string"
          }}
          onSearch={onSearch}
          loading={false}
        />
        <div className="images images-group-container">
          {images?.map((image, i) => {
            return (
              <div className="images_item" key={i}>
                <div className="images_item-control">
                  <Checkbox
                    defaultChecked={!!formik.values["images"]?.includes(image?.name)}
                    onChange={(e) => setImagesToForm(formik, image)}
                  >
                    <div
                      className={`images_item-label ${
                        formik.values["images"]?.includes(image?.name)
                          ? "images_item-label-active"
                          : ""
                      }`}
                    >
                      <img src={image?.imageIconUrl || imageIcon} alt="error" />
                      <p>{image?.displayName}</p>
                      <img
                        src={selectedIcon}
                        alt="selected"
                        className="images_item-label-active-icon"
                      />
                    </div>
                  </Checkbox>
                </div>
              </div>
            );
          })}
        </div>

        <div className="action_form">
          <button
            type="reset"
            onClick={() => {
              dispatch(closeModal("image-group"));
              formik.setValues({});
            }}
            className="dashboard-cancel-btn"
          >
            Cancel
          </button>
          <button type="submit" className="dashboard-confirm-btn">
            Confirm
          </button>
        </div>
      </form>
    </div>
  );
}

export default ImageGroupForm;
