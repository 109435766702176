import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { CREATE_USER_GROUP_FIELDS as fields } from "../../constants/fields";
import React, { useEffect, useState } from "react";
import { InputTextArea, InputSelect, InputText } from "../../../../components/InputFields";
import { closeModal } from "../../../../data/slices/modals";
import { Tag, message } from "antd";
import { Checkbox } from "antd";
import { getImageGroups } from "../../../../data/slices/imageGroup";
import {
  createGroup,
  getResources,
  getVerbs,
  updateGroup
} from "../../../../data/slices/userGroup";
import { Button } from "antd";
import { getUsers } from "../../../../data/slices/memberSlice";
import { getResourcesForCreateRole } from "../../../../data/slices/resourcesSlice";

function UserGroupForm({ isEdit, id, group }) {
  const dispatch = useDispatch();

  const { data: actions } = useSelector((state) => state.usersGroup.verbs);
  const { data: resources } = useSelector((state) => state.usersGroup.resources);
  const { members } = useSelector((state) => state.member);
  const { resources_verbs } = useSelector((state) => state.resources_verbs);
  const [validVerbs, setValidVerbs] = useState([]);

  let rule = {
    verbs: [],
    resource: ""
  };

  const formik = useFormik({
    initialValues: {
      name: isEdit ? group?.name : "",
      description: isEdit ? group?.description : "",
      rules: isEdit
        ? group.rules.map((item, index) => {
            return {
              verbs: item?.verbs,
              resource: item?.resource
            };
          })
        : [rule]
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(2, "Too Short!")
        .max(70, "Too Long!")
        .matches(/^[a-zA-Z0-9-]+$/, "name should alphanumeric and hyphen only")
        .required("This field is required"),
      description: Yup.string()
    }),
    onSubmit: (values) => {
      if (!isEdit) {
        dispatch(createGroup(values))
          .unwrap()
          .then((res) => {
            message.success(`User Groups created successfully.`);
            dispatch(closeModal("user-group"));
          })
          .catch((err) => {
            message.error(err.message || "something went wrong");
          });
      } else {
        dispatch(updateGroup({ values, id }))
          .unwrap()
          .then(() => {
            message.success(`User Groups updated successfully.`);
            dispatch(closeModal("user-group"));
          })
          .catch((err) => {
            message.error(err.message || "something went wrong");
          });
      }
    }
  });

  useEffect(() => {
    dispatch(getVerbs({}));
    dispatch(getResources({}));
    dispatch(getResourcesForCreateRole());
  }, []);

  // const actions = ["create", "read", "update", "delete", "use", "launch"];
  // const resources = ["users", "roles", "templates", "serviceaccounts"];

  const onUpdateAction = (values) => {
    if (values?.[0] === "*") {
      return actions?.verbs;
    } else {
      return values;
    }
  };

  const onUpdateResources = (values) => {
    if (values?.[0] === "*") {
      return resources?.resources;
    } else {
      return values;
    }
  };
  function addElementAtIndex(arr, index, element) {
    arr.splice(index, 0, element);
    return arr;
  }

  function removeElementAtIndex(arr, index) {
    const newArr = [...arr];
    newArr.splice(index, 1);
    return newArr;
  }

  useEffect(() => {
    if (isEdit) {
      setValidVerbs(group.rules.map((item) => item?.resource));
    }
  }, [isEdit]);
  //validateVerbs return the actions based on the selected resource
  const validateVerbs = (index) => {
    if (validVerbs?.length === 0 || !validVerbs[index]) {
      return [];
    }
    return resources_verbs?.[`${validVerbs[index]}`];
  };
  // check the valid actions for each selected resource (saved in the state)
  const updateStateAtIndex = (index, newValue) => {
    if (newValue) {
      setValidVerbs((prevState) => {
        const newArray = [...prevState];
        newArray[index] = newValue;
        return newArray;
      });
    } else {
      removeFromStateAtIndex(index);
    }
  };
  // Function to remove an item from the array by index
  const removeFromStateAtIndex = (index) => {
    setValidVerbs((prevState) => {
      const newArray = [...prevState];
      newArray.splice(index, 1);
      return newArray;
    });
  };

  const handleUsersOnEdit = () => {
    if (isEdit) {
      let cleanUsers = members?.filter((el) => {
        if (el?.roles) {
          return !el.roles.some((role) => role?.name === group?.name);
        }
        return true;
      });
      return cleanUsers;
    }
  };

  const handleExistingUsers = () => {
    if (isEdit) {
      let cleanUsers = members?.filter((el) => {
        if (el?.roles) {
          return el.roles.some((role) => role?.name === group?.name);
        }
        return true;
      });
      return cleanUsers;
    }
  };
  console.log(handleExistingUsers());
  return (
    <div className="form_user user_group_modal">
      <form action="" onSubmit={formik.handleSubmit}>
        {fields?.map((el, index) => (
          <React.Fragment key={index}>
            <InputText formik={formik} field={el} />
          </React.Fragment>
        ))}
        {formik.values.rules?.map((el, index) => (
          <div className="dynamic_fields" key={index}>
            <div className="dynamic-fields-header">
              <label htmlFor="Actions">Actions</label>

              <div className="add_remove_btn">
                <Button
                  type="primary"
                  onClick={() =>
                    formik.setFieldValue(
                      "rules",
                      addElementAtIndex(formik.values.rules, index + 1, rule)
                    )
                  }
                >
                  +
                </Button>
                {formik.values.rules?.length > 1 && (
                  <Button
                    type="primary"
                    danger
                    onClick={() => {
                      formik.setFieldValue(
                        "rules",
                        removeElementAtIndex(formik.values.rules, index)
                      );
                      removeFromStateAtIndex(index);
                    }}
                  >
                    -
                  </Button>
                )}
              </div>
            </div>
            <div className="verbs">
              <label htmlFor="Actions" className="label">
                Resources
              </label>

              <Checkbox.Group
                options={resources?.resources}
                onChange={(e) => {
                  formik.setFieldValue(`rules[${index}].resource`, e[0]);

                  updateStateAtIndex(index, e[0]);
                }}
                value={onUpdateResources(formik.values.rules[index]?.resource)}
              />
            </div>
            <div className="verbs">
              <label htmlFor="Actions" className="label">
                Verbs
              </label>
              <Checkbox.Group
                options={validateVerbs(index) || [{ value: "" }]}
                onChange={(e) => {
                  formik.setFieldValue(`rules[${index}].verbs`, e);
                }}
                value={onUpdateAction(formik.values?.rules[index]?.verbs)}
              />
            </div>
          </div>
        ))}
        {/* <InputSelect
          label="Add New Users To the Group"
          formik={formik}
          options={
            isEdit
              ? handleUsersOnEdit()?.map((item) => ({
                  value: item?.username,
                  label: item?.username
                }))
              : members?.map((item) => ({
                  value: item?.username,
                  label: item?.username
                }))
          }
          defaultOptions={[]}
          onChange={(e) => {
            formik.setFieldValue(`users`, e);
          }}
          className="select"
          value={formik.values?.users}
        /> */}
        {handleExistingUsers() && isEdit && (
          <>
            existing users :{" "}
            {handleExistingUsers()?.map((el, index) => (
              <Tag key={index}>{el?.fullName}</Tag>
            ))}
          </>
        )}
        <div className="action_form">
          <button
            type="reset"
            onClick={() => {
              dispatch(closeModal("user-group"));
              formik.setValues({});
            }}
            className="dashboard-cancel-btn"
          >
            Cancel
          </button>
          <button type="submit" className="dashboard-confirm-btn">
            Confirm
          </button>
        </div>
      </form>
    </div>
  );
}

export default UserGroupForm;
