import React from "react";
import { Slider } from "antd";
import "./_Slider.scss";

const SliderValues = ({ label, onChange, marks, max, min, labelIcon, value }) => {
  return (
    <div className="slider-antd">
      <p className="slider-label">
        <img src={labelIcon} alt="" />
        {label}
      </p>
      <Slider
        marks={marks}
        step={1}
        defaultValue={value}
        value={value}
        max={max}
        min={min || 0}
        onChange={onChange}
      />
    </div>
  );
};
export default SliderValues;
