const stringBoolean = (item) => {
  if (item === true || item === "true") {
    return true;
  }

  if (item === false || item === "false") {
    return false;
  }
  return false;
};

export { stringBoolean };
