import React from "react";
import ReactDOM from "react-dom/client";
import { store } from "./data";
import { Provider } from "react-redux";
import "./main.scss";
import RoutesProvider from "./routes";
import AuthProvider from "./components/AuthProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId="713595650553-dkf7n8dopnot8sih7b7srbd7b177pltf.apps.googleusercontent.com">
    <Provider store={store}>
      <AuthProvider>
        <RoutesProvider />
      </AuthProvider>
    </Provider>
  </GoogleOAuthProvider>
  // </React.StrictMode>
);
