import { Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as React from "react";
import { Menu, MenuItem, ListItemIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { logout } from "../../data/slices/authSlice";
import powerIcon from "../../assets/icons/power.svg";
import refreshIcon from "../../assets/icons/refresh.svg";
import fi_down from "../../assets/icons/fi_down.svg";
import { Can } from "../../context/permissions/Can";
import { ReactComponent as ProfileIcon } from "./../../assets/icons/userProfile.svg";

const MenuHeader = ({ isAdmin }) => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // if (width < 1090) {
  //   return null;
  // }

  return (
    <div className="menu">
      <div className="avatar" onClick={handleClick}>
        <Avatar
          src={user?.profilePicture}
          className="profile__avatar"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        />
        <img src={fi_down} alt="" />
      </div>

      {anchorEl && (
        <Menu
          className="menu_main"
          id="basic-menu1"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {/* <Can I="switch" a="admin">
            <MenuItem
              className="logout_item"
              onClick={() => {
                handleClose();
                navigate("/settings");
              }}
            >
              <ListItemIcon>
                <ProfileIcon />
              </ListItemIcon>
              Profile
            </MenuItem>
          </Can> */}

          <MenuItem
            className="logout_item"
            onClick={() => {
              dispatch(logout());
            }}
          >
            <ListItemIcon>
              <img src={powerIcon} alt="" />
            </ListItemIcon>
            Sign Out
          </MenuItem>
        </Menu>
      )}
    </div>
  );
};

export default MenuHeader;
