import { Avatar, Drawer, List, Spin } from "antd";
import React, { useEffect, useState } from "react";
import closeIcon from "../../../../../assets/icons/x-close.svg";
import { ReactComponent as PowerIcon } from "../../../../../assets/icons/power-icon.svg";
import { ReactComponent as CpuIcon } from "../../../../../assets/icons/cpu-icon.svg";
import { ReactComponent as RamIcon } from "../../../../../assets/icons/ram-icon.svg";
import { ReactComponent as DiskIcon } from "../../../../../assets/icons/disk-icon.svg";
import { Menu } from "@mui/material";
import { handleQuery } from "../../../../../helpers/searchQuery";
import { useDispatch, useSelector } from "react-redux";
import { getUsersPerOrg } from "../../../../../data/slices/usersPerOrg";
import { getActivePlan } from "../../../../../data/slices/plans";
import {
  getResourcesUsage,
  getStats,
  getUsageUsers
} from "../../../../../data/slices/statisticSlice";
import { LoadingOutlined } from "@ant-design/icons";

const loadingHandler = async (promises, setLoading) => {
  setLoading(true);
  await Promise.all(promises);
  return setLoading(false);
};

const formatUsers = (users) => {
  return users.map((user) => {
    return {
      name: user?.fullName,
      email: user?.email,
      id: user?.username
    };
  });
};

const formatNumber = (number = 0) => {
  if (isNaN(number)) return 0;
  return parseFloat(number.toFixed(3));
};

const getUsage = (resource) => {
  if (!resource) return {};
  const { cpuHourUsage, ramHourUsage, storageUsage, duration } = resource;
  return {
    CPUUsage: cpuHourUsage,
    RAMUsage: ramHourUsage,
    StorageUsage: storageUsage,
    Duration: duration / 60
  };
};

function OrgDetails({ id, open, handleClose, data }) {
  const [loading, setLoading] = useState(false);
  const { org } = data || {};
  const dispatch = useDispatch();
  const { operators } = useSelector((state) => state.users);
  const { activePlan } = useSelector((state) => state.pricing);
  const { stats, resources, userUsage } = useSelector((state) => state.stats);

  useEffect(() => {
    if (open) {
      loadingHandler(
        [
          dispatch(
            getUsersPerOrg({
              query: handleQuery({ page: 1, limit: 100, searchString: data?.id }),
              orgId: data?.id,
              userType: "1"
            })
          ),
          dispatch(getUsageUsers({ query: { orgId: data.id } })),
          dispatch(getStats({ query: { orgId: data.id } })),
          dispatch(getResourcesUsage({ query: { orgId: data.id } })),
          dispatch(
            getActivePlan({
              orgId: data?.id,
              subscriptionId: data?.org?.spec?.stripeSubscriptionID
            })
          )
        ],
        setLoading(false)
      );
    }
  }, [open]);

  const cpu = activePlan?.cpu?.number || 0;
  const ram = activePlan?.ram?.number || 0;

  const storage = activePlan?.storageFeature?.number || 0;

  const { CPUUsage, RAMUsage, StorageUsage, Duration } = getUsage(resources);

  return (
    <Drawer placement="right" open={open} width="35%" closable={false} className="user_drawer">
      <div className="head_user_drawer">
        <div className="user_avatar">
          <span>
            <p className="title">{org?.spec?.name}</p>
          </span>
        </div>
        <div className="close_ion" onClick={() => handleClose(id)}>
          <img src={closeIcon}></img>
        </div>
      </div>
      {loading ? (
        <div className="org-deatils-spinner">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 45 }} spin />} />
        </div>
      ) : (
        <div className="drawer-body">
          <div className="count-org">
            <div className="key_value">
              <p>Active Session</p>
              <p>{stats?.activeSessionsCount}</p>
            </div>
            <div className="key_value">
              <p>Template Count</p>
              <p>{stats?.templatesCount}</p>
            </div>
            <div className="key_value">
              <p>users Count</p>
              <p>{stats?.usersCount}</p>
            </div>
          </div>
          <div className="org-consomation">
            <p className="title_list">Consomation</p>
            <div className="org-consomation-list">
              <div className="org-consomation-list-item">
                <div>
                  <CpuIcon />
                  <p>CPU</p>
                </div>
                <div>
                  <p>{formatNumber(CPUUsage)} Cpu/h</p>
                  <p>{cpu} cpu/h</p>
                </div>
              </div>
              <div className="org-consomation-list-item">
                <div>
                  <RamIcon />
                  <p>Ram</p>
                </div>
                <div>
                  <p>{formatNumber(RAMUsage)} Ram/h</p>
                  <p>{ram} ram/h</p>
                </div>
              </div>
              <div className="org-consomation-list-item">
                <div>
                  <DiskIcon />
                  <p>Disk</p>
                </div>
                <div>
                  <p>{formatNumber(StorageUsage)} G</p>
                  <p>{storage} G</p>
                </div>
              </div>
              <div className="org-consomation-list-item">
                <div>
                  <DiskIcon />
                  <p>Duration</p>
                </div>
                <div>
                  <p>{formatNumber(Duration)} H</p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
          <div className="org-users ">
            <p className="title_list">Users</p>
            <div className="org-users-list">
              <div className="org-users-list-header ">
                <p>Name</p>
              </div>
              <List
                dataSource={formatUsers(operators)}
                renderItem={(item, index) => (
                  <UserItem
                    key={index}
                    item={item}
                    usage={userUsage ? userUsage[item?.id] : null}
                    resourges={activePlan?.spec?.metadata}
                  />
                )}
              />
            </div>
          </div>
        </div>
      )}
    </Drawer>
  );
}

export default OrgDetails;

const UserItem = ({ item, usage, resourges }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { cpu = 0, ram = 0, storage = 0 } = resourges || {};
  const { CPUUsage, RAMUsage, StorageUsage, Duration } = getUsage(usage);

  return (
    <List.Item>
      <List.Item.Meta avatar={<Avatar />} title={item.name} description={item.email} />
      <div className="power" onClick={handleClick}>
        <PowerIcon />
      </div>
      {anchorEl && (
        <Menu
          className="menu_main usage_user_menu"
          id="basic-menu1"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <div className="menu-item">
            <div className="menu-item-info">
              <CpuIcon />
              <p>CPU</p>
            </div>
            <div className="menu-item-usage">
              <p>{formatNumber(CPUUsage)} Cpu/h</p>
              <p>{cpu} cpu/h</p>
            </div>
          </div>
          <div className="menu-item">
            <div className="menu-item-info">
              <RamIcon />
              <p>Ram</p>
            </div>
            <div className="menu-item-usage">
              <p>{formatNumber(RAMUsage)} Ram/h</p>
              <p>{ram} ram/h</p>
            </div>
          </div>
          <div className="menu-item">
            <div className="menu-item-info">
              <DiskIcon />
              <p>Disk</p>
            </div>
            <div className="menu-item-usage">
              <p>{formatNumber(StorageUsage)} G</p>
              <p>{storage} G</p>
            </div>
          </div>
          <div className="menu-item">
            <div className="menu-item-info">
              <DiskIcon />
              <p>Duration</p>
            </div>
            <div className="menu-item-usage">
              <p>{formatNumber(Duration)} H</p>
              <p></p>
            </div>
          </div>
        </Menu>
      )}
    </List.Item>
  );
};
