import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";

const getUniqueObjects = (arr) => {
  const uniqueMap = new Map();
  arr.forEach((obj) => {
    uniqueMap.set(obj.sessionID, obj);
  });
  return Array.from(uniqueMap.values());
};

const initialState = {
  desktopSessions: {
    data: [],
    state: "idle",
    error: null,
    message: null,
    loading: null
  },
  sessions: [],
  activeSessions: [],
  connectedSessions: [],
  activeSession: null,
  audioEnabled: true,
  recordingEnabled: true,
  mousePosition: 1,
  state: "idle"
};

const equal = function (o1, o2) {
  return o1.name === o2.name && o1.namespace === o2.namespace;
};

export const newSession = createAsyncThunk(
  "api/sessions",
  async ({ namespace, template, sessionConfig, shouldPersist }) => {
    let data;
    try {
      const response = await axiosInstance.post(`/sessions`, {
        namespace,
        template,
        sessionConfig,
        shouldPersist
      });
      response.data.template = template;

      data = await response.data;

      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const getSessions = createAsyncThunk("api/get-sessions", async () => {
  let data;
  try {
    const response = await axiosInstance.get(`/sessions/me`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const getActiveSessions = createAsyncThunk("api/get-active-sessions", async (query) => {
  let data;
  try {
    const response = await axiosInstance.get(`/sessions`, { params: query });
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});
//delete
export const deleteSession = createAsyncThunk(
  "api/delete-sessions",
  async ({ name, namespace }, thunkApi) => {
    let data;
    try {
      const response = await axiosInstance.delete(`/sessions/${namespace}/${name}`);
      data = await response.data;
      console.log({ response });
      if (response.status === 200) {
        thunkApi.dispatch(getActiveSessions());
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const sessionsSlice = createSlice({
  name: "sessions",
  initialState,
  reducers: {
    toggle_audio(state, data) {
      state.audioEnabled = data.payload;
      // state.audioEnabled = !state.audioEnabled;
    },

    toggle_recording(state, data) {
      state.recordingEnabled = data.payload;
      // state.recordingEnabled = data;
      // state.recordingEnabled = !state.recordingEnabled;
    },

    new_session(state, data) {
      console.log("new_session");
      let session = { ...data.payload };
      session["active"] = true;
      state.sessions.push(session);
    },
    set_active_session(state, data) {
      console.log("set_active_session");
      let session = data.payload;
      state.activeSession = session;
    },
    delete_session(state, data) {
      state.sessions = state.sessions.filter((val) => {
        return !equal(val, data);
      });
      if (state.sessions.length !== 0) {
        state.sessions[0].active = true;
      }
    },
    set_mouse_position(state, data) {
      state.mousePosition = data.payload;
    },
    set_connected_sessions(state, data) {
      const { name: sessionID, timer = 0 } = data?.payload;
      state.connectedSessions = getUniqueObjects([
        ...state.connectedSessions,
        { sessionID, timer }
      ]);
    },
    set_disconnected_sessions(state, data) {
      const { name: sessionID } = data?.payload;
      state.connectedSessions = state.connectedSessions.filter(
        (session) => session?.sessionID !== sessionID
      );
    }
  },
  extraReducers: {
    [newSession.pending]: (state) => {
      state.desktopSessions.error = null;
      state.desktopSessions.state = "loading";
    },
    [newSession.fulfilled]: (state, action) => {
      const session = action.payload;
      state.desktopSessions.data = [...state.desktopSessions.data, session];
      state.sessions = [...state.sessions, session];
      state.activeSession = session;

      state.desktopSessions.state = "success";
    },
    [newSession.rejected]: (state, action) => {
      state.desktopSessions.error = action.error.message;
      state.desktopSessions.state = "error";
    },
    //get sessions
    [getSessions.pending]: (state) => {
      state.state = "loading";
    },
    [getSessions.fulfilled]: (state, action) => {
      const sessions = action.payload.sessions;
      // state.sessions = [...sessions];
      state.desktopSessions.data = [...sessions];

      state.state = "success";
    },
    [getSessions.rejected]: (state, action) => {
      state.state = "error";
    },
    [getActiveSessions.pending]: (state) => {
      state.state = "loading";
    },
    [getActiveSessions.fulfilled]: (state, action) => {
      const sessions = action.payload.sessions;
      state.activeSessions = sessions;
      state.state = "success";
    },
    [getActiveSessions.rejected]: (state, action) => {
      state.state = "error";
    }
  }
});
export const {
  toggle_audio,
  toggle_recording,
  set_active_session,
  delete_session,
  new_session,
  set_mouse_position,
  set_connected_sessions,
  set_disconnected_sessions
} = sessionsSlice.actions;

export default sessionsSlice.reducer;
