import { Button, Drawer, Space, Avatar } from "antd";
import closeIcon from "../../../../assets/icons/x-close.svg";
import Tabs from "../../../../components/Tabs/Tabs";
import { useSelector } from "react-redux";
import { changeUserDetailsTab } from "../../../../data/slices/tabsSlice";

function UserDetails({ id, open, handleClose, data, ...rest }) {
  const { userDetailsTabs } = useSelector((state) => state.tabs);
  const user = data?.user || null;
  // console.log(user);

  const checkState = (key, value) => {
    if (key === "State" && value === "active") {
      return "active";
    } else if (key === "State" && value === "inactive") {
      return "inactive";
    }
  };
  let userDetails = {
    Username: user?.name,
    FullName: user?.fullName || "--",
    Email: user?.email || "--",
    Password: user?.password || "--",
    State: user?.isActive ? "active" : "inactive",
    Organization: user?.organization || "--"
  };

  const mappedObject = Object.keys(userDetails).map((key) => (
    <div className="key_value" key={key}>
      <p className="key">{key}</p>
      <p className={`${checkState(key, userDetails[key])}`}>{userDetails[key]}</p>
    </div>
  ));

  return (
    <Drawer placement="right" open={open} width="35%" closable={false} className="user_drawer">
      <div className="head_user_drawer">
        <div className="user_avatar">
          <Avatar size={50} />
          <span>
            <p className="title">{user?.name}</p>
            <p className="email">{user?.email || "--"}</p>
          </span>
        </div>
        <div className="close_ion" onClick={() => handleClose(id)}>
          <img src={closeIcon}></img>
        </div>
      </div>
      {/* //content drawer  */}
      <div className="user_content">
        <Tabs
          tabs={userDetailsTabs.tabs}
          defaultTab={userDetailsTabs.activeTab}
          setTab={changeUserDetailsTab}
          type={userDetailsTabs.type}
        />

        {userDetailsTabs.activeTab === "0" ? (
          <div className="user_details">{mappedObject}</div>
        ) : (
          <div className="user_settings">user settings</div>
        )}
      </div>
    </Drawer>
  );
}

export default UserDetails;
