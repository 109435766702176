import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { CREATE_USER_FIELDS as fields } from "../../constants/fields";
import React, { useEffect, useState } from "react";
import { createUser, updateUser, updateUserPassword } from "../../../../data/slices/memberSlice";
import { InputSelect, InputText } from "../../../../components/InputFields";
import UploadImage from "../UploadImage/UploadImage";
import { closeModal, openModal } from "../../../../data/slices/modals";
import { Button, message } from "antd";
import { getUserGroups } from "../../../../data/slices/userGroup";
import { Grid } from "@mui/material";
import { getOrgs } from "../../../../data/slices/organizations";

function UserForm({ isEdit, id, user, orgId }) {
  const dispatch = useDispatch();

  const { usersGroup } = useSelector((state) => state.usersGroup);
  const { organizations } = useSelector((state) => state.organization);

  const formik = useFormik({
    initialValues: {
      profilePicture: isEdit ? user?.profilePicture : "",
      username: isEdit ? user.username : "",
      fullName: isEdit ? user.fullName : "",
      email: isEdit ? user.email : "",
      roles: isEdit ? user?.roles?.map((el) => el.name) : [],
      isActive: isEdit ? user?.isActive : true,
      password: ""
    },
    validationSchema: Yup.object().shape({
      username: Yup.string()
        .min(2, "Too Short!")
        .max(70, "Too Long!")
        .required("This field is required"),
      fullName: Yup.string()
        .min(2, "Too Short!")
        .max(70, "Too Long!")
        .required("This field is required"),
      password: isEdit
        ? Yup.string()
        : Yup.string().min(4, "Too Short!").max(24, "Too Long!").required("This field is required"),
      email: isEdit
        ? Yup.string()
        : Yup.string()
            .email()
            .min(2, "Too Short!")
            .max(70, "Too Long!")
            .required("This field is required"),
      roles: Yup.array().min(1).required("This field is required")
    }),
    onSubmit: (values) => {
      // values["location"] = {
      //   country: {
      //     name: countryInfo?.name,
      //     code: countryInfo?.phonecode,
      //     currency: countryInfo?.currency,
      //     isoCode: countryInfo?.isoCode,
      //     flag: encodeURIComponent(countryInfo?.flag)
      //   },
      //   state: {
      //     name: stateInfo
      //   },
      //   region: {
      //     name: countryInfo?.timezones[0]?.zoneName
      //   }
      // };

      if (!isEdit) {
        dispatch(createUser(values))
          .unwrap()
          .then((res) => {
            message.success(`User created successfully.`);
            dispatch(closeModal("user-modal"));
          })
          .catch((err) => {
            message.error(err.message || "something went wrong");
          });
      } else {
        // const { password } = values;
        // if (password)
        //   dispatch(updateUserPassword({ password, id }))
        //     .unwrap()
        //     .then((res) => {
        //       message.success(`User password updated successfully.`);
        //     })
        //     .catch((err) => {
        //       message.error(err.message || "something went wrong");
        //     });
        delete values.password;
        delete values.orgId;
        delete values.username;
        delete values.email;
        dispatch(updateUser({ values, id, orgId }))
          .unwrap()
          .then((res) => {
            message.success(`User updated successfully.`);
            dispatch(closeModal("user-modal"));
          })
          .catch((err) => {
            message.error(err.message || "something went wrong");
          });
      }
    }
  });
  useEffect(() => {
    if (orgId) {
      dispatch(getOrgs({ query: "" }));
      formik.setFieldValue("orgId", orgId);
    }
  }, [orgId]);

  return (
    <div className="form_user">
      <form action="" onSubmit={formik.handleSubmit}>
        <UploadImage formik={formik} />

        <Grid container columnSpacing={2}>
          {fields
            .filter((input) =>
              isEdit
                ? input.name !== "username" && input.name !== "email" && input.name !== "password"
                : input
            )
            .map((el, index) => (
              <Grid item md={6} sm={12} xs={12} key={index}>
                {<InputText formik={formik} field={el} />}
              </Grid>
            ))}
        </Grid>
        {orgId && (
          <InputSelect
            label="Organization"
            single={true}
            formik={formik}
            options={organizations?.map((item) => ({
              value: item.metadata?.name,
              label: item.spec?.name
            }))}
            defaultOptions={orgId || null}
            onChange={(e) => {
              formik.setFieldValue("orgId", e);
            }}
            field="orgs"
            onDropdownVisibleChange={() => {
              dispatch(getOrgs({ query: "" }));
            }}
            disabled={isEdit ? true : false}
          ></InputSelect>
        )}
        <InputSelect
          label="User Role"
          formik={formik}
          options={usersGroup?.map((item) => ({
            value: item?.name,
            label: item?.name
          }))}
          defaultOptions={formik.values.roles}
          onChange={(e) => {
            formik.setFieldValue("roles", e);
          }}
          field="roles"
          onDropdownVisibleChange={() => {
            dispatch(getUserGroups({ query: "" }));
          }}
          notFoundContent={
            <Button onClick={() => dispatch(openModal("user-group"))} style={{ width: "100%" }}>
              Add Role
            </Button>
          }
        ></InputSelect>

        {/* <LacationInput
          countryInfo={countryInfo}
          stateInfo={stateInfo}
          setCountryInfo={setCountryInfo}
          setStateInfo={setStateInfo}
        /> */}

        {/* <div className="active_user">
          <Switch
            defaultChecked={formik.values.isActive}
            onChange={(value) => formik.setFieldValue("isActive", value)}
          />
          <p>
            Active
            <span> User Account Status</span>
          </p>
        </div> */}

        <div className="action_form">
          <button
            onClick={() => {
              dispatch(closeModal("user-modal"));
              formik.setValues({});
            }}
            className="dashboard-cancel-btn"
          >
            Cancel
          </button>
          <button type="submit" className="dashboard-confirm-btn">
            Confirm
          </button>
        </div>
      </form>
    </div>
  );
}

export default UserForm;
