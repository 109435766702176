export const CREATE_USER_FIELDS = [
  {
    name: "username",
    type: "string",
    placeholder: "Enter your username",
    label: "Username *"
  },
  {
    name: "fullName",
    type: "text",
    placeholder: "Enter your fullname",
    label: "fullname *"
  },
  {
    name: "password",
    type: "password",
    placeholder: "Enter your password",
    label: "password"
  },
  {
    name: "email",
    type: "email",
    placeholder: "Enter your email address",
    label: "Email Address *"
  }
  // {
  //   name: "roles",
  //   type: "select",
  //   placeholder: "Select",
  //   label: "User Group",
  //   mode: "multiple",
  // },
];

export const CREATE_USER_GROUP_FIELDS = [
  {
    name: "name",
    type: "string",
    placeholder: "Enter the group name",
    label: "Group Name*"
  }
];
