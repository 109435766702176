import { Avatar, message, Upload } from "antd";
import { ReactComponent as AvatarUploadIcon } from "../../../../assets/icons/dashboard-file-upload-avatar.svg";
import { ReactComponent as CloudUploadIcon } from "../../../../assets/icons/dashboard-upload-cloud-pic.svg";
import { uploadFile } from "../../../../helpers/uploadFile";
import { UserOutlined } from "@ant-design/icons";
import { useState } from "react";

const { Dragger } = Upload;

const customRequest = async ({ file, onSuccess, onError }) => {
  try {
    const additionalFields = {
      upload_preset: "ph4slroc"
    };
    const imageUrl = await uploadFile(file, additionalFields);
    onSuccess({ url: imageUrl });
  } catch (error) {
    onError(error);
  }
};

const props = {
  showUploadList: false,
  maxCount: 1,
  name: "file",
  customRequest,
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  }
};

const UploadImage = ({ formik }) => {
  const [profilePic, setProfilePic] = useState(formik.values?.profilePicture);
  const onFileUpload = (info) => {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      formik.values.profilePicture = info.file.response.url;
      setProfilePic(info.file.response.url);
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <div className="section_upload">
      <Avatar src={profilePic} icon={<UserOutlined />} size={70} />

      <Dragger className="dragger" {...props} onChange={onFileUpload}>
        <div className="dragger-content">
          <CloudUploadIcon />
          <div className="dragger-content-text">
            <p className="upload-text">
              Click to upload <span>or drag and drop</span>{" "}
            </p>
            <p className="upload-hint">SVG, PNG, JPG or GIF (max. 800x400px)</p>
          </div>
        </div>
      </Dragger>
    </div>
  );
};

export default UploadImage;
