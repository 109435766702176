import React from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
// import axios from "../../../../utils/axios";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

const UploadFile = ({ formik, field, isEdit, defaultFiles }) => {
  const [files, setFiles] = useState([]);

  const customRequest = async ({ file, onSuccess, onError }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "ph4slroc");

      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/dj6kxvxqb/image/upload",
        formData,
        {
          // headers: {
          //   "Content-Type": "multipart/form-data"
          // }
        }
      );
      message.success(`${file.name} file uploaded successfully`);
      setFiles([
        ...files,
        { url: response.data.secure_url, uid: file.uid, status: "done", name: file.name }
      ]);
      onSuccess(response.data, file);
    } catch (error) {
      console.error("Error uploading file:", error);
      message.error(`${file.name} file upload failed.`);
      onError(error);
    }
  };
  //remove file
  const handleRemove = (file) => {
    const newFiles = files.filter((el) => el.uid != file.uid);
    setFiles(newFiles);
  };

  const customProps = {
    name: "file",
    customRequest: customRequest,
    onRemove: handleRemove,
    listType: "picture",
    accept: "image/*",
    fileList: files
  };

  //set uploaded files in formik values
  useEffect(() => {
    formik.setFieldValue(field, [...files.map((el) => el.url)]);
  }, [files]);

  //when edit set default files in the state
  useEffect(() => {
    if (isEdit) {
      let newFiles = defaultFiles?.map((el, index) => {
        return { uid: `file-${index}`, name: `file-${index}`, status: "done", url: el };
      });
      setFiles([...newFiles]);
    }
    // else {
    //   setFiles([]);
    // }
  }, [defaultFiles]);

  return (
    <Upload {...customProps}>
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    </Upload>
  );
};

export default UploadFile;
