import { Drawer, Avatar } from "antd";
import closeIcon from "../../../../assets/icons/x-close.svg";
import Tabs from "../../../../components/Tabs/Tabs";
import { useSelector } from "react-redux";
import { changeGroupDetailsTab } from "../../../../data/slices/tabsSlice";
import useWindowSize from "../../../../hooks/useWindowSize";
import { List } from "antd";

function ImageGroupDetails({ id, open, handleClose, data, ...rest }) {
  let { width } = useWindowSize();
  const { groupDetailsTabs } = useSelector((state) => state.tabs);
  const group = data?.group || null;

  let groupDetails = {
    Name: group?.displayName,
    Date: new Date(group?.creationTimestamp).toLocaleDateString() || "--"
  };

  const mappedObject = Object.keys(groupDetails).map((key) => (
    <div className="key_value" key={key}>
      <p className="key">{key}</p>
      <p>{groupDetails[key]}</p>
    </div>
  ));

  return (
    <Drawer
      placement="right"
      open={open}
      width={width > 1000 ? "35%" : "large"}
      closable={false}
      className="user_drawer"
    >
      <div className="head_user_drawer">
        <div className="user_avatar">
          <span>
            <p className="title">{group?.displayName}</p>
          </span>
        </div>
        <div className="close_ion" onClick={() => handleClose(id)}>
          <img src={closeIcon}></img>
        </div>
      </div>
      {/* //content drawer  */}
      <div className="user_content">
        <Tabs
          tabs={groupDetailsTabs.tabs}
          defaultTab={groupDetailsTabs.activeTab}
          setTab={changeGroupDetailsTab}
          type={groupDetailsTabs.type}
        />

        {groupDetailsTabs.activeTab === "0" ? (
          <div className="user_details">
            {mappedObject}
            <ListImages images={group?.images} />
          </div>
        ) : (
          <div className="user_settings">group settings</div>
        )}
      </div>
    </Drawer>
  );
}

export default ImageGroupDetails;

const ListImages = ({ images }) => {
  return (
    <>
      <div className="title_list">Images</div>
      <div className="title_name_list">Name</div>
      <List
        dataSource={images}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta avatar={<Avatar />} title={item} />
          </List.Item>
        )}
      />
      {/* <Pagination meta={meta} onChange={getUsers} /> */}
    </>
  );
};
