import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { message, Modal } from "antd";
import { useState } from "react";
import { CREATE_ORG as fields } from "../../../../UserGroup/constants/fields";
import { InputSelect, InputText } from "../../../../../components/InputFields";
import { getUsers } from "../../../../../data/slices/memberSlice";
import { createOrg, updateOrg } from "../../../../../data/slices/organizations";
import "./_CreateOrg.scss";

function CreateOrg({ id, open, handleClose, data = null, ...rest }) {
  const dispatch = useDispatch();

  const [confirmLoading, setConfirmLoading] = useState(false);
  const { members, loading } = useSelector((state) => state.member);

  const formik = useFormik({
    initialValues: {
      orgName: "",
      owner: null
    },
    validationSchema: Yup.object().shape({
      orgName: Yup.string().required(),
      owner: Yup.string().required()
    }),
    onSubmit: (values) => {
      if (data?.isEdit) {
        try {
          dispatch(updateOrg({ values: { ...values }, id: data.id })).then((res) => {
            if (res.payload?.ok) {
              message.success("Organization Updated Successfully");
              handleClose(id);
              formik.resetForm();
            } else {
              message.error(res.error.message);
            }
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          dispatch(createOrg({ ...values })).then((res) => {
            if (res.payload?.ok) {
              message.success("Organization Created Successfully");
              handleClose(id);
              formik.setValues({});
            } else {
              message.error(res.error.message);
            }
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
  });

  useEffect(() => {
    if (data?.isEdit) {
      formik.setFieldValue("orgName", data?.org?.spec?.name);
      formik.setFieldValue("owner", data?.org?.spec?.owner);
    }
    // dispatch(getUsers({ query: "" }));
  }, [data]);

  return (
    <Modal
      open={open}
      onCancel={() => {
        handleClose(id);
        formik.resetForm();
      }}
      onOk={formik.handleSubmit}
      okText={`${data?.isEdit ? "Edit" : "Create"} Organization`}
      title={`${data?.isEdit ? "Edit" : "Create"} Organization`}
      wrapClassName="form_create_org"
    >
      <form action="" onSubmit={formik.handleSubmit}>
        {fields.map((el, index) => (
          <div key={index}>
            {el.type === "select" ? (
              <InputSelect
                single={true}
                label={el.label}
                formik={formik}
                options={members?.map((item) => ({
                  value: item?.username,
                  label: item?.fullName
                }))}
                defaultOptions={data?.isEdit ? formik.values?.owner : null}
                onChange={(e) => {
                  formik.setFieldValue(`owner`, e);
                }}
                className="select"
                value={formik.values?.owner}
                placeholder="select the owner"
                field="owner"
                onDropdownVisibleChange={() => dispatch(getUsers({ query: "" }))}
              />
            ) : (
              <InputText formik={formik} field={el} />
            )}
          </div>
        ))}
      </form>
    </Modal>
  );
}

export default CreateOrg;
