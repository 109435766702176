import { createSlice, current, createAsyncThunk } from "@reduxjs/toolkit";
import { INVOICES } from "../../constants";
import axios from "../../utils/axios";
const initInvoices = (INVOICES) => {
  return INVOICES.map((member) => {
    return { ...member, checked: false };
  });
};
const initialState = {
  histories: initInvoices(INVOICES),
  meta: {},
  state: "idle",
  error: null,
  message: null,
  loading: null
};

export const BillingSlice = createSlice({
  name: "billingSlice",
  initialState,
  reducers: {
    selectAll: (state, action) => {
      state.histories = current(state).histories.map((group) => {
        return { ...group, checked: action.payload };
      });
    },
    selectById: (state, action) => {
      state.histories = current(state).histories.map((group) => {
        if (group?.id === action.payload.id) return { ...group, checked: action.payload.value };
        return group;
      });
    }
  },
  extraReducers: {}
});

export const { selectAll, selectById } = BillingSlice.actions;

export default BillingSlice.reducer;
