import axios from "axios";
import { store } from "../data";
import { logout } from "../data/slices/authSlice";
import { closeAllModals } from "../data/slices/modals";
import { Config } from "../config";

export const headers = {
  Accept: "application/json",
  "Content-Type": "application/json"
};
const axiosInstance = axios.create({
  baseURL: Config.REACT_APP_API_URL,
  headers,
  withCredentials: true
});

const publicAxios = axios.create({
  baseURL: Config.REACT_APP_API_URL,
  withCredentials: true
});

//request interceptor to add the auth token header to requests
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      config.headers["x-session-token"] = accessToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

let isRefreshing = false;
let refreshSubscribers = [];

const onRefreshed = (newAccessToken) => {
  refreshSubscribers.forEach((callback) => callback(newAccessToken));
  refreshSubscribers = [];
};

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { dispatch } = store;

    const previousRequest = error?.config;
    if (error?.response?.status === 401 && !previousRequest?.sent) {
      previousRequest.sent = true;

      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const response = await publicAxios.get("auth/operator/refresh_token");
          const newAccessToken = response.data.token;
          localStorage.setItem("token", newAccessToken);
          previousRequest.headers["x-session-token"] = newAccessToken;
          isRefreshing = false;
          onRefreshed(newAccessToken);
          return axiosInstance(previousRequest);
        } catch (err) {
          isRefreshing = false;
          dispatch(closeAllModals());
          dispatch(logout());
        }
      } else {
        return new Promise((resolve) => {
          refreshSubscribers.push((newAccessToken) => {
            previousRequest.headers["x-session-token"] = newAccessToken;
            resolve(axiosInstance(previousRequest));
          });
        });
      }
    }
    return Promise.reject((error.response && error.response.data) || "Something went wrong");
  }
);

export default axiosInstance;
