import React, { useEffect, useState } from "react";

function formatTime(milliseconds) {
  const seconds = Math.floor((milliseconds / 1000) % 60);
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");
  return `${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`;
}

const getInitalTime = (session) => {
  const startAt = session?.podRunningAt;
  return startAt ? Date.now() - new Date(startAt).getTime() : session?.timer;
};

const SessionCounter = ({ session }) => {
  const [timer, setTimer] = useState(getInitalTime(session));
  useEffect(() => {
    setTimeout(() => {
      setTimer(timer + 1000);
    }, 1000);
  }, [timer]);
  return <div className="sessionCounter">{formatTime(timer)}</div>;
};

export default SessionCounter;
