import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import closeIcon from "../../../../assets/icons/dashboard-x-close.svg";
import React from "react";
import InviteUserForm from "../InviteUserForm/InviteUserForm";

function InviteUserModal({ id, open, handleClose, data, ...rest }) {
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {}}
      className="ta-modal user_modal dashboard-user-modal"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px"
          }
        }
      }}
    >
      <DialogTitle id="alert-dialog-title" className="modal_title">
        <span className="label"> Invite Users </span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          <img src={closeIcon} alt="" />
        </span>
      </DialogTitle>
      <p className="subtitle">Invite colleagues to join your Organization.</p>

      <DialogContent className="ta-modal-content-scroll">
        <InviteUserForm closeModal={handleClose} />
      </DialogContent>
    </Dialog>
  );
}

export default InviteUserModal;
