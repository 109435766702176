import { createSlice, current, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { MEMBERS } from "../../constants";

const initOperators = (MEMBERS) => {
  return MEMBERS.map((member) => {
    return { ...member, checked: false };
  });
};

const initialState = {
  operators: [],
  searchItems: [],
  meta: {},
  user: {},
  state: "idle",
  error: null,
  message: null,
  loading: null,
  searchString: ""
};

//get users
export const getUsersPerOrg = createAsyncThunk(
  "api/users-per-org",
  async ({ query, orgId, userType }) => {
    let data;
    // let str = page && limit && search ? `?search=${search}page=${page}&limit=${limit}` : "";
    let searchStr = orgId ? `&orgId=${orgId}` : "";
    let strType = userType ? `&userType=${userType}` : "";

    try {
      const response = await axios.get(`/users${query}${strType}${searchStr}`);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const createOperator = createAsyncThunk("api/crate-Operator", async (values, thunkApi) => {
  let data;
  try {
    const response = await axios.post(`/users`, { ...values });
    data = await response.data;
    if (response.status === 200) {
      thunkApi.dispatch(getUsersPerOrg({ query: "?page=1&limit=8" }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;

    return Promise.reject(error.message ? error.message : error.error);
  }
});
// delete getOperator
export const deleteOperator = createAsyncThunk("api/delete-op", async (id, thunkApi) => {
  let data;
  try {
    const response = await axios.delete(`/users/${id}`);
    data = await response.data;
    if (response.status === 200) {
      thunkApi.dispatch(getUsersPerOrg({ query: "?page=1&limit=8" }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : error.error);
  }
});

// get one op
export const getOperator = createAsyncThunk("api/get-op", async (id) => {
  let data;
  try {
    const response = await axios.get(`/users/${id}`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : error.error);
  }
});

// edit op
export const updateOperator = createAsyncThunk("api/edit-op", async (query, thunkApi) => {
  let data;
  let { values, id } = query;
  try {
    const response = await axios.put(`/users/${id}`, { ...values });
    data = await response.data;

    if (response.status === 200) {
      thunkApi.dispatch(getUsersPerOrg({ query: "?page=1&limit=8" }));
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : error.error);
  }
});

export const memberSlice = createSlice({
  name: "operators",
  initialState,
  reducers: {
    selectAll: (state, action) => {
      state.operators = current(state).operators.map((member) => {
        return { ...member, checked: action.payload };
      });
    },
    selectById: (state, action) => {
      state.operators = current(state).operators.map((member) => {
        if (member.id === action.payload.id) return { ...member, checked: action.payload.value };
        return member;
      });
    },
    setUsers: (state, action) => {
      state.operators = action.payload;
    },
    setSearchString: (state, action) => {
      state.searchString = action.payload;
    }
  },
  extraReducers: {
    //get operators
    [getUsersPerOrg.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [getUsersPerOrg.fulfilled]: (state, action) => {
      const users = action.payload.users;
      const newData = initOperators(users);
      state.operators = newData;
      state.searchItems = newData;
      state.meta = action.payload.meta;
      state.state = "success";
    },
    [getUsersPerOrg.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },

    //create op
    [createOperator.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [createOperator.fulfilled]: (state, action) => {
      const op = action.payload;
      state.state = "success";
    },
    [createOperator.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    //get op
    [getOperator.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [getOperator.fulfilled]: (state, action) => {
      const org = action.payload;
      state.state = "success";
      state.operator = org;
    },
    [getOperator.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    }
  }
});

export const { selectAll, selectById, setUsers, setSearchString } = memberSlice.actions;

export default memberSlice.reducer;
