import { Checkbox, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import imageIcon from "./../../../../assets/icons/procreate.svg";
import selectedIcon from "./../../../../assets/icons/dashboard-image-selected.svg";
import { getTemplates } from "../../../../data/slices/templates";
import visibilityIcon from "../../assets/icons/visivility.svg";

const AllowedTemplates = ({ formik }) => {
  const setImagesToForm = (formik, image = "images") => {
    let images = formik.values.allowedDesktopTemplatesFeature.templates || [];
    const imageIndex = images?.findIndex((img) => img === image?.name);
    if (imageIndex !== -1) images = images?.filter((_, i) => i !== imageIndex);
    else images = [...images, image?.name];
    formik.setFieldValue("allowedDesktopTemplatesFeature.templates", images);
  };

  const { templates } = useSelector((state) => state.templates);
  const isAllAllowed =
    formik.values.allowedDesktopTemplatesFeature.templates &&
    formik.values.allowedDesktopTemplatesFeature.templates[0] === "*";
  const [allowAll, setAllaowAll] = useState(isAllAllowed);

  const dispatch = useDispatch();
  useEffect(() => {
    setAllaowAll(isAllAllowed);
  }, [formik.values.allowedDesktopTemplatesFeature.templates]);

  useEffect(() => {
    if (!templates.data && !allowAll) dispatch(getTemplates({}));
  }, [allowAll]);
  return (
    <div className="allowed-templates">
      <div className="visibility_section">
        <div className="ldap_checkbox">
          <div className="title_item">
            <img src={visibilityIcon} alt="" />
            <p>Allow All Templates</p>
          </div>
          <Switch
            checked={allowAll}
            onChange={(e) => {
              setAllaowAll(e);
              formik.values.allowedDesktopTemplatesFeature.templates = e ? ["*"] : [];
            }}
          ></Switch>
        </div>
      </div>

      <div className="images-group-container" style={{ height: allowAll ? "0" : "300px" }}>
        {templates?.data?.map((image, i) => {
          return (
            <div className="images_item" key={i}>
              <div className="images_item-control">
                <Checkbox
                  defaultChecked={
                    !!formik.values.allowedDesktopTemplatesFeature.templates?.includes(image?.name)
                  }
                  onChange={(e) =>
                    setImagesToForm(formik, image, "allowedDesktopTemplatesFeature.templates")
                  }
                >
                  <div
                    className={`images_item-label ${
                      formik.values.allowedDesktopTemplatesFeature.templates?.includes(image?.name)
                        ? "images_item-label-active"
                        : ""
                    }`}
                  >
                    <img src={image?.imageIconUrl || imageIcon} alt="error" />
                    <p>{image?.displayName}</p>
                    <img
                      src={selectedIcon}
                      alt="selected"
                      className="images_item-label-active-icon"
                    />
                  </div>
                </Checkbox>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AllowedTemplates;
