import { createSlice, current, createAsyncThunk } from "@reduxjs/toolkit";
import { IMAGE_GROUPS } from "../../constants";
import axios from "../../utils/axios";

const initialState = {
  ImageGroups: null,
  meta: {},
  searchGroups: [],
  state: "idle",
  error: null,
  message: null,
  loading: null,
  searchString: ""
};

export const getImageGroups = createAsyncThunk("api/image-groups", async ({ query }) => {
  let data;
  // let str = page && limit ? `?page=${page}&limit=${limit}` : "";
  try {
    const response = await axios.get(`/image-groups${query}`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const createImageGroup = createAsyncThunk(
  "api/create-image-groups",
  async (values, thunkApi) => {
    let data;
    try {
      const response = await axios.post(`/image-groups`, { ...values });
      data = await response.data;
      if (response.status === 200) {
        thunkApi.dispatch(getImageGroups({ query: "?page=1&limit=8" }));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : error.error);
    }
  }
);

export const updateImageGroup = createAsyncThunk(
  "api/update-image-groups",
  async (query, thunkApi) => {
    let data;
    let { values, id } = query;
    try {
      const response = await axios.put(`/image-groups/${id}`, { ...values });
      data = await response.data;
      if (response.status === 200) {
        thunkApi.dispatch(getImageGroups({ query: "?page=1&limit=8" }));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : error.error);
    }
  }
);

export const deleteImageGroup = createAsyncThunk(
  "api/delete-image-groups",
  async (id, thunkApi) => {
    let data;
    try {
      const response = await axios.delete(`/image-groups/${id}`);
      data = await response.data;
      if (response.status === 200) {
        thunkApi.dispatch(getImageGroups({ query: "?page=1&limit=8" }));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const imageGroupsSlice = createSlice({
  name: "imageGroups",
  initialState,
  reducers: {
    selectAll: (state, action) => {
      state.ImageGroups = current(state).ImageGroups.map((group) => {
        return { ...group, checked: action.payload };
      });
    },
    selectById: (state, action) => {
      console.log(action);
      state.ImageGroups = current(state).ImageGroups.map((group) => {
        if (group?.name === action.payload.id) return { ...group, checked: action.payload.value };
        return group;
      });
    },
    setGroups: (state, action) => {
      state.ImageGroups = action.payload;
    },
    setSearchString: (state, action) => {
      state.searchString = action.payload;
    }
  },
  extraReducers: {
    [getImageGroups.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [getImageGroups.fulfilled]: (state, action) => {
      const groups = action.payload.imageGroups;
      const meta = action.payload.meta;
      state.meta = meta;
      state.ImageGroups = groups;
      state.searchGroups = groups;
      state.state = "success";
    },
    [getImageGroups.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    },
    [createImageGroup.pending]: (state) => {
      state.error = null;
      state.state = "loading";
    },
    [createImageGroup.fulfilled]: (state, action) => {
      state.state = "success";
    },
    [createImageGroup.rejected]: (state, action) => {
      state.error = action.error.message;
      state.state = "error";
    }
  }
});

export const { selectAll, selectById, setGroups, setSearchString } = imageGroupsSlice.actions;

export default imageGroupsSlice.reducer;
