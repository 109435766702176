import MenuHeader from "../Menu";
import HamburgerMenuTrigger from "../HamburgerMenuTrigger";
import useWindowSize from "../../hooks/useWindowSize";
import { ReactComponent as Logo } from "../../assets/icons/GoMyDeskLogo.svg";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const Header = ({ isAdmin }) => {
  const { width } = useWindowSize();
  const { session } = useParams();
  const { headerTitle } = useSelector((state) => state.tabs);
  const { mousePosition } = useSelector((state) => state.sessions);
  const fullHeight = mousePosition >= 10 && document.fullscreenElement && session;

  function replaceSlashWithGreaterThan(inputString) {
    if (typeof inputString !== "string") {
      throw new Error("Input must be a string.");
    }
    if (inputString === "/") return "Dashboard";
    const firstSlashIndex = inputString.indexOf("/");
    if (firstSlashIndex === 0) {
      return inputString.slice(1).replace(/\//g, "/");
    }
    return inputString.replace(/\//g, "/");
  }

  return (
    <>
      <header className={isAdmin ? "header_admin" : "header"} id={fullHeight ? "hideHeader" : ""}>
        {isAdmin && width >= 1090 && (
          <div className="header__group">
            {isAdmin ? (
              <p className="admin_page_title">
                {replaceSlashWithGreaterThan(window.location.pathname)}
              </p>
            ) : (
              <Logo />
            )}
          </div>
        )}
        <div className="header__group">
          {isAdmin && <HamburgerMenuTrigger />}
          {width < 1090 && isAdmin && <Logo />}
          <MenuHeader isAdmin={isAdmin} />
        </div>
      </header>
    </>
  );
};

export default Header;
