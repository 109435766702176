import { Drawer } from "antd";
import "./_planDetails.scss";
import doneIcon from "../../assets/icons/done.svg";
import { openModal } from "../../../../data/slices/modals";
import { useDispatch } from "react-redux";
import closeIcon from "../../../../assets/icons/x-close.svg";

function PlanDetails({ id, open, handleClose, data, ...rest }) {
  const dispatch = useDispatch();

  return (
    <Drawer placement="right" closable={false} open={open} width="33%">
      <div className="close_ion" onClick={() => handleClose(id)}>
        <img src={closeIcon}></img>
      </div>
      <div className="pricing-yearly">
        <div className="pricing-item">
          <div className="pricing-item-box">
            <span className="ribbon"></span>
            <div className="plan-name">{data?.plan?.spec?.name}</div>
            <div className="plan-text">
              <span className="text-bold">{data?.plan?.spec?.description}</span>
            </div>

            <div className="plan-price-content">
              <div className="plan-price">
                ${data?.plan?.spec?.default_price?.spec?.unit_amount || 0}
                <span className="month-text">/month</span>
              </div>
            </div>

            <div className="plan-features">
              {data?.plan &&
                Object.keys(data?.plan?.spec?.metadata).map((item, i) => (
                  <div key={i} className="plan-feature-item">
                    {item} {data?.plan?.spec?.metadata?.[item]}
                  </div>
                ))}
            </div>
            <div className="plan-button">
              <a
                onClick={() => {
                  dispatch(
                    openModal("create-offer", {
                      isEdit: true,
                      id: data?.plan?.spec?.id,
                      plan: data?.plan
                    })
                  );
                  handleClose(id);
                }}
                className="plan-button-blue "
              >
                Update Plan
              </a>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default PlanDetails;
