import { Avatar, Drawer, Tag } from "antd";
import React from "react";
import closeIcon from "../../../../assets/icons/x-close.svg";
import SessionCounter from "../SessionCounter/SessionCounter";
import Status from "../../../../components/Status/Status";

const handleCategories = (caterories) => {
  return caterories?.map((category) => {
    return <Tag color="green">{category}</Tag>;
  });
};

function DisplaySessionModal({ id, open, handleClose, data }) {
  return (
    <Drawer placement="right" open={open} width="35%" closable={false} className="user_drawer">
      <div className="head_user_drawer">
        <div className="user_avatar">
          <Avatar size={50} src={data?.template?.imageIconUrl} />
          <span>
            <p className="title">{data?.template?.name}</p>
          </span>
        </div>
        <div className="close_ion" onClick={() => handleClose(id)}>
          <img src={closeIcon}></img>
        </div>
      </div>
      <div className="user_content">
        <div className="user_details">
          <div className="key_value">
            <p className="key">Status</p>
            <p>
              <Status label={data?.status} type={data?.status} />
            </p>
          </div>
          <div className="key_value">
            <p className="key">User</p>
            <p>
              <Tag color="green">{data?.user}</Tag>
            </p>
          </div>
          <div className="key_value">
            <p className="key">Duration</p>
            <p>
              <Tag>
                {["connected", "pending"]?.includes(data?.status) ? (
                  <SessionCounter session={data} />
                ) : (
                  "00h 00m 00s"
                )}
              </Tag>
            </p>
          </div>
          <div className="key_value">
            <p className="key">CPU</p>
            <p>
              <Tag color="green">{data?.sessionConfig?.cpu}</Tag>
            </p>
          </div>
          <div className="key_value">
            <p className="key">Memory</p>
            <p>
              <Tag color="green">{data?.sessionConfig?.ram}</Tag>
            </p>
          </div>
          <div className="key_value">
            <p className="key">Storage</p>
            <p>
              <Tag color="green">{data?.sessionConfig?.storage}</Tag>
            </p>
          </div>
          <div className="key_value">
            <p className="key">categories</p>
            <p>{handleCategories(data?.template?.categories)}</p>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default DisplaySessionModal;
