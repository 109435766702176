import React, { useEffect } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { useState } from "react";
import axios from "axios";
import "./_DraggerUpload.scss";
import uploadIcon from "../../assets/icons/upload.svg";
const { Dragger } = Upload;

const DraggerUpload = ({ formik, field, isEdit, defaultFiles, files, setFiles }) => {
  const customRequest = async ({ file, onSuccess, onError }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "ph4slroc");

      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/dj6kxvxqb/image/upload",
        formData,
        {
          // headers: {
          //   "Content-Type": "multipart/form-data"
          // }
        }
      );
      message.success(`${file.name} file uploaded successfully`);
      setFiles([
        ...files,
        { url: response.data.secure_url, uid: file.uid, status: "done", name: file.name }
      ]);
      onSuccess(response.data, file);
    } catch (error) {
      console.error("Error uploading file:", error);
      message.error(`${file.name} file upload failed.`);
      onError(error);
    }
  };
  //remove file
  const handleRemove = (file) => {
    const newFiles = files.filter((el) => el.uid != file.uid);
    setFiles(newFiles);
  };

  const customProps = {
    name: "file",
    customRequest: customRequest,
    onRemove: handleRemove,
    listType: "picture",
    // accept: "image/*,.pdf",
    fileList: files,
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        message.error("You can only upload image files!");
      }
      return isImage;
    }
  };
  //set uploaded files in formik values
  useEffect(() => {
    formik?.setFieldValue(field, [...files.map((el) => el.url)]);
  }, [files]);
  //when edit set default files in the state
  useEffect(() => {
    if (isEdit) {
      let newFiles = defaultFiles?.map((el, index) => {
        return { uid: `file-${index}`, name: `file-${index}`, status: "done", url: el };
      });
      setFiles([...newFiles]);
    }
  }, [defaultFiles]);
  return (
    <div className="dragger">
      <label htmlFor="drag">Upload Image*</label>
      <Dragger {...customProps}>
        <div className="dragger-content">
          <p className="upload-drag-icon">
            <img src={uploadIcon} alt="" />
          </p>
          <div className="texts">
            <p className="upload-text">Click or drag file to this area to upload</p>
            <p className="upload-hint">SVG, PNG, JPG or GIF (max. 800x400px)</p>
          </div>
          <p></p>
        </div>
      </Dragger>
    </div>
  );
};
export default DraggerUpload;
