import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import closeIcon from "../../../../../assets/icons/dashboard-x-close.svg";
import UserForm from "../../UserForm/UserForm";

function UserModal({ id, open, handleClose, data, ...rest }) {
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {}}
      className="ta-modal user_modal dashboard-user-modal"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "829px"
          }
        }
      }}
    >
      <DialogTitle id="alert-dialog-title" className="modal_title">
        <span className="label">{data?.isEdit ? "Edit User" : "Add New User"}</span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          <img src={closeIcon} alt="" />
        </span>
      </DialogTitle>
      <p className="subtitle">Choose a username and complete all user details.</p>

      <DialogContent className="ta-modal-content-scroll">
        <UserForm
          isEdit={data?.isEdit || false}
          id={data?.isEdit ? data?.id : null}
          user={data?.isEdit ? data?.user : null}
          orgId={data?.orgId ? data?.orgId : null}
        />
      </DialogContent>
    </Dialog>
  );
}

export default UserModal;
