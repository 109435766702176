import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../data/slices/modals";
import ImageGroupModal from "../../features/ImageGroup/components/Modals/GroupModal/GroupModal";
import ImageModal from "../../features/ImageGroup/components/Modals/ImageModal/ImageModal";
import CreateOffer from "../../features/Offers/components/Modals/CreateOffer";
import CreateOrg from "../../features/Organizations/components/Modals/CreateOrg/CreateOrg";
import UserGroupModal from "../../features/UserGroup/components/Modals/GroupModal/GroupModal";
import UserModal from "../../features/Users/components/Modals/UserModal";

import { ModalExample, ModalConfirm } from "../Modals";
import AssignModal from "../Modals/AssignModal/AssignModal";
import InviteUser from "../../features/UsersPerOrg/components/InviteUser/InviteUser";
import InviteUserModal from "../../features/UsersPerOrg/components/InviteUserModal/InviteUserModal";
import DisplaySessionModal from "../../features/ActiveSessions/components/Modals/DisplaySessionModal";
import OrgDetails from "../../features/Organizations/components/Modals/OrgDetails/OrgDetails";
import InviteUserListModal from "../../features/UsersPerOrg/components/InviteUserListModal/InviteUserListModal";

const ModalsProvider = (props) => {
  const { modals } = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const modalState = (id, key) => {
    const res = modals.find((modal) => modal.id === id);
    return res[key];
  };
  const handleClose = (id) => {
    dispatch(closeModal(id));
  };
  return (
    <>
      <ModalExample
        id="modal-example"
        open={modalState("modal-example", "open")}
        data={modalState("modal-example", "data")}
        handleClose={handleClose}
      />
      <UserModal
        id="user-modal"
        open={modalState("user-modal", "open")}
        data={modalState("user-modal", "data")}
        handleClose={handleClose}
      />
      <ModalConfirm
        id="confirm-modal"
        open={modalState("confirm-modal", "open")}
        data={modalState("confirm-modal", "data")}
        handleClose={handleClose}
      />
      <UserGroupModal
        id="user-group"
        open={modalState("user-group", "open")}
        data={modalState("user-group", "data")}
        handleClose={handleClose}
      />
      <ImageGroupModal
        id="image-group"
        open={modalState("image-group", "open")}
        data={modalState("image-group", "data")}
        handleClose={handleClose}
      />
      <ImageModal
        id="image-modal"
        open={modalState("image-modal", "open")}
        data={modalState("image-modal", "data")}
        handleClose={handleClose}
      />

      <AssignModal
        id="assign-modal"
        open={modalState("assign-modal", "open")}
        data={modalState("assign-modal", "data")}
        handleClose={handleClose}
      />
      <CreateOrg
        id="create-org"
        open={modalState("create-org", "open")}
        data={modalState("create-org", "data")}
        handleClose={handleClose}
      />
      <CreateOffer
        id="create-offer"
        open={modalState("create-offer", "open")}
        data={modalState("create-offer", "data")}
        handleClose={handleClose}
      />

      <InviteUserModal
        id="invite-user-modal"
        open={modalState("invite-user-modal", "open")}
        data={modalState("invite-user-modal", "data")}
        handleClose={handleClose}
      />

      <DisplaySessionModal
        id="active-session-details"
        open={modalState("active-session-details", "open")}
        data={modalState("active-session-details", "data")}
        handleClose={handleClose}
      />
      <OrgDetails
        id="org-details-modal"
        open={modalState("org-details-modal", "open")}
        data={modalState("org-details-modal", "data")}
        handleClose={handleClose}
      />
      <InviteUserListModal
        id="invite-user-list-modal"
        open={modalState("invite-user-list-modal", "open")}
        data={modalState("invite-user-list-modal", "data")}
        handleClose={handleClose}
      />
    </>
  );
};

export default ModalsProvider;
