import React from "react";
import "./_PlanVisibility.scss";
import { InputSelect } from "../../../../components/InputFields";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrgs } from "../../../../data/slices/organizations";
import { Switch } from "antd";
import visibilityIcon from "../../assets/icons/visivility.svg";
function PlanVisibility({ formik, isPublic, setIsPublic }) {
  const { state } = useLocation();
  const dispatch = useDispatch();

  const { organizations } = useSelector((state) => state.organization);
  const handlePublic = (e) => {
    setIsPublic(e);
    formik.setFieldValue("orgId", "");
  };
  return (
    <div className="visibility_section">
      {/* <span className="title_visibility">
        <p style={{ textAlign: "center" }}>Visibility</p>
      </span> */}
      <div className="ldap_checkbox">
        <div className="title_item">
          <img src={visibilityIcon} alt="" />
          <p> Visibility</p>
        </div>
        <div className="switch">
          <Switch checked={isPublic} onChange={(e) => handlePublic(e)}></Switch>
          <p className={isPublic ? "status-active" : "status-inactive"}>
            {isPublic ? "Public" : "Private"}
          </p>
        </div>
      </div>
      {!isPublic && (
        <span className="org_item">
          <InputSelect
            label="Add Organisation"
            single={true}
            formik={formik}
            options={organizations?.map((item) => ({
              value: item.metadata?.name,
              label: item.metadata?.name
            }))}
            defaultOptions={formik?.values?.orgId || null}
            value={state?.id || formik?.values?.orgId}
            onChange={(e) => {
              formik.setFieldValue("orgId", e);
            }}
            field={`orgId`}
            onDropdownVisibleChange={() => {
              dispatch(getOrgs({ query: "" }));
            }}
            disabled={isPublic}
          ></InputSelect>
        </span>
      )}
    </div>
  );
}

export default PlanVisibility;
