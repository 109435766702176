const CPU_MARKS = {
  1: "1",
  2: "2",
  4: "4",
  6: "6",
  8: "8",
  10: "10",
  12: "12"
};
const GPU_MARKS = {
  1: "1",
  2: "2",
  3: "3",
  4: "4"
};
const DISK_MARKS = {
  1: "1",
  12: "12",
  24: "24",
  36: "36",
  48: "48",
  60: "60"
};
const RAM_MARKS = {
  1: "1",
  8: "8",
  16: "16",
  24: "24",
  32: "32"
};

export { CPU_MARKS, DISK_MARKS, RAM_MARKS, GPU_MARKS };
