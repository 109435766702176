import { NavLink } from "react-router-dom";
import { ReactComponent as UserGroupIcon } from "../../assets/icons/sidebar-users.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/homes.svg";
import { ReactComponent as OrgIcon } from "../../assets/icons/apartment.svg";
import { ReactComponent as ImageGroupIcon } from "../../assets/icons/sidebar-images.svg";
import { useDispatch, useSelector } from "react-redux";
import { closeSidebar } from "../../data/slices/settingsSlice";
import { setHeaderTitle } from "../../data/slices/tabsSlice";

const NavigationLink = ({ icon = null, route = "/", children, title }) => {
  const dispatch = useDispatch();
  return (
    <NavLink
      to={route}
      className="navigation_link"
      onClick={() => {
        dispatch(closeSidebar());
        dispatch(setHeaderTitle(title));
      }}
      data-testid="navigation-link"
    >
      {icon}
      <span className="navigation_link__label">{children}</span>
    </NavLink>
  );
};

const Navigation = () => {
  return (
    <div className="navigation__container" data-testid="navigation-container">
      <NavigationLink title="Dashboard" route="/" icon={<HomeIcon />}>
        Dashboard
      </NavigationLink>
      <NavigationLink title="organizations" route="/organizations" icon={<OrgIcon />}>
        Organization
      </NavigationLink>
      <NavigationLink title="Operators" route="/users" icon={<UserGroupIcon />}>
        Operators
      </NavigationLink>
      <NavigationLink title="Group Management" route="/user-groups" icon={<UserGroupIcon />}>
        User Groups
      </NavigationLink>
      {/* <NavigationLink title="User & Group Management" route="/users" icon={<UserGroupIcon />}>
        User & Group
      </NavigationLink> */}
      <NavigationLink title="Images & Group Management" route="/images" icon={<ImageGroupIcon />}>
        Image & Group
      </NavigationLink>
      <NavigationLink title="Plans" route="/plans" icon={<ImageGroupIcon />}>
        Plans
      </NavigationLink>
      <NavigationLink title="Active Sessions" route="/active-sessions" icon={<ImageGroupIcon />}>
        Active Sessions
      </NavigationLink>
    </div>
  );
};

export default Navigation;
