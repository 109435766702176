function convertValuesToStrings(obj) {
  const result = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key] = String(obj[key]);
    }
  }

  return result;
}

export { convertValuesToStrings };
