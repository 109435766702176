import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  isSidebarOpened: false,
  isClipboardEnabled: true,
  clipboardText: "",
  isProfileNavOpen: false
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.isSidebarOpened = !current(state).isSidebarOpened;
    },
    openSidebar: (state) => {
      state.isSidebarOpened = true;
    },
    closeSidebar: (state) => {
      state.isSidebarOpened = false;
    },
    toggleIsClipboardEnabled: (state) => {
      state.isClipboardEnabled = !state.isClipboardEnabled;
    },
    setClipboardText: (state, { payload }) => {
      state.clipboardText = payload;
    },
    toggleProfileNav: (state) => {
      state.isProfileNavOpen = !current(state).isProfileNavOpen;
    }
  }
});

export const {
  toggleSidebar,
  openSidebar,
  closeSidebar,
  toggleIsClipboardEnabled,
  setClipboardText,
  toggleProfileNav
} = settingsSlice.actions;

export default settingsSlice.reducer;
