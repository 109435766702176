import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Drawer, Space } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./_CreatePlan.scss";
import closeIcon from "../../assets/icons/close.svg";
import DraggerUpload from "../DraggerUpload/DraggerUpload";
import { message } from "antd";
import { CREATE_OFFER_FIELDS as fields } from "../../../UserGroup/constants/fields";
import { InputText } from "../../../../components/InputFields";
import { createOffer, updateOffer } from "../../../../data/slices/plans";
import { getOrgs } from "../../../../data/slices/organizations";
import { useLocation } from "react-router-dom";
import PlanFeaturesForm from "../PlanFeaturesForm/PlanFeaturesForm";
import PlanVisibility from "../PlanVisibility/PlanVisibility";
import AllowedTemplates from "../AllowedTemplates/AllowedTemplates";
import MonthlyYearlyPrice from "../MonthlyYearlyPrice/MonthlyYearlyPrice";
let cpu_p = 0.028;
let ram_p = 0.007;

function CreatePlan({ id, open, handleClose, data, ...rest }) {
  const dispatch = useDispatch();
  const { organizations } = useSelector((state) => state.organization);
  const { state } = useLocation();
  const [files, setFiles] = useState([]);
  const [isFreePlan, setIsFreePlan] = useState(false);

  const [isPublic, setIsPublic] = useState(true);

  const formik = useFormik({
    initialValues: {
      active: true,
      name: "",
      description: "",
      // price: "",
      prices: [
        {
          amount: null,
          recurringInterval: "month",
          recurringIntervalCount: 1
        },
        {
          amount: null,
          recurringInterval: "year",
          recurringIntervalCount: 1
        }
      ],

      images: [],
      cpuFeature: {
        number: ""
      },
      gpuFeature: {
        number: ""
      },
      ramFeature: {
        number: ""
      },
      storageFeature: {
        number: ""
      },
      numberOfUsersFeature: {
        number: ""
      },
      persistentStorageFeature: {
        isOn: false
      },
      ldapFeature: {
        isOn: false
      },
      quotaManagementFeature: {
        isOn: false
      },
      skuConfigurationFeature: {
        isOn: false
      },
      snapshotFeature: {
        isOn: false
      },
      trafficFilteringFeature: {
        isOn: false
      },
      supportFeature: {
        value: ""
      },
      orgId: "",
      allowedDesktopTemplatesFeature: {
        templates: ["*"]
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required field"),
      description: Yup.string().required("Description is required field"),

      // price: Yup.number().required("Amount is required"),
      cpuFeature:
        !data?.isEdit &&
        Yup.object().shape({
          number: Yup.number().positive("CPU must be a positive number").required("CPU is required")
        }),
      gpuFeature:
        !data?.isEdit &&
        Yup.object().shape({
          number: Yup.number().min(0, "GPU must be at least 0").required("CPU is required")
        }),
      numberOfUsersFeature:
        !data?.isEdit &&
        Yup.object().shape({
          number: Yup.number()
            .positive("Number of users must be a positive number")
            .required("Number of users is required")
        }),
      ramFeature:
        !data?.isEdit &&
        Yup.object().shape({
          number: Yup.number().positive("RAM must be a positive number").required("RAM is required")
        }),
      storageFeature:
        !data?.isEdit &&
        Yup.object().shape({
          number: Yup.number()
            .positive("Storage must be a positive number")
            .required("Storage is required")
        }),
      supportFeature:
        !data?.isEdit &&
        Yup.object().shape({
          value: Yup.string().required("Support type is required")
        }),
      prices: isFreePlan
        ? null
        : Yup.array().of(
            Yup.object().shape({
              amount: Yup.number()
                .typeError("Amount must be a valid number")
                .required("Amount is required")
                .test(
                  "valid-amount",
                  "Amount must be greater than or equal to 0.5",
                  (value) => value !== undefined && value >= 0.5
                ),
              recurringInterval: Yup.string().required("Recurring interval is required"),
              recurringIntervalCount: Yup.number()
                .required("Billing Period is required")
                .typeError("Billing Period is required")
            })
          )
    }),

    onSubmit: (values) => {
      if (!isFreePlan) {
        if (values.prices[0].amount === 0 || values.prices[1].amount === 0) {
          if (values.prices[0].amount != 0 || values.prices[1].amount != 0) {
            return message.error("If you want to create a free plan, both prices should be 0");
          }
        }
      }
      // images validations
      if (values?.images?.length === 0) {
        message.error("Plan Image is Required");
        return;
      }
      // org validations
      if (!isPublic) {
        if (!values.orgId) {
          message.error("Organization is required");
          return;
        }
      }

      // values.price = Math.ceil(values.price);
      if (data?.isEdit === false) {
        dispatch(
          createOffer({
            ...values
          })
        )
          .unwrap()
          .then((res) => {
            if (res?.ok === true) {
              message.success("Plan Created Successfully");
              formik.resetForm();
              handleClose(id);
            } else {
              message.error("Something went wrong");
            }
          })
          .catch((error) => {
            console.log(error);
            message.error("Something went wrong");
          });
      } else {
        console.log("heloo");
        dispatch(
          updateOffer({
            values: {
              ...values
            },
            id: data?.id
          })
        )
          .unwrap()
          .then((res) => {
            if (res?.ok === true) {
              message.success("Plan Updated Successfully");
              formik.resetForm();
              handleClose(id);
            } else {
              message.error("Something went wrong");
            }
          })
          .catch((error) => {
            console.log(error);
            message.error("Something went wrong");
          });
      }
    }
  });

  const findPricePerType = (prices, type) => {
    if (prices) {
      let price = prices?.find((el) => el.recurring.interval == type.toLowerCase());
      return price;
    }
  };

  //set values for edit
  useEffect(() => {
    if (data?.isEdit) {
      formik.setFieldValue("images", data?.plan?.images);
      formik.setFieldValue("cpuFeature.number", data?.plan?.cpuFeature?.number);
      formik.setFieldValue("gpuFeature.number", data?.plan?.gpuFeature?.number);
      formik.setFieldValue("ramFeature.number", data?.plan?.ramFeature?.number);
      formik.setFieldValue("storageFeature.number", data?.plan?.storageFeature?.number);
      formik.setFieldValue("ldapFeature.isOn", data?.plan?.ldapFeature?.isOn);
      //news
      formik.setFieldValue(
        "skuConfigurationFeature.isOn",
        data?.plan?.skuConfigurationFeature?.isOn
      );
      formik.setFieldValue(
        "trafficFilteringFeature.isOn",
        data?.plan?.trafficFilteringFeature?.isOn
      );
      formik.setFieldValue("quotaManagementFeature.isOn", data?.plan?.quotaManagementFeature?.isOn);
      formik.setFieldValue("numberOfUsersFeature.number", data?.plan?.numberOfUsersFeature?.number);
      formik.setFieldValue("supportFeature.value", data?.plan?.supportFeature?.value);
      formik.setFieldValue("snapshotFeature.isOn", data?.plan?.snapshotFeature?.isOn);

      formik.setFieldValue(
        "persistentStorageFeature.isOn",
        data?.plan?.persistentStorageFeature?.isOn
      );
      formik.setFieldValue(
        "allowedDesktopTemplatesFeature.templates",
        data?.plan?.allowedDesktopTemplatesFeature?.templates
      );
      //handle edit prices based on plan type (if "day" => free plan)
      if (data?.plan?.prices?.[0]?.recurring?.interval === "day") {
        setIsFreePlan(true);
        formik.setFieldValue("prices", [
          {
            id: data?.plan?.prices?.[0]?.id,
            amount: 0,
            recurringIntervalCount: data?.plan?.prices?.[0]?.recurring?.intervalCount,
            recurringInterval: data?.plan?.prices?.[0]?.recurring?.interval
          }
        ]);
      } else {
        setIsFreePlan(false);
        formik.setFieldValue("prices", [
          {
            id: findPricePerType(data?.plan?.prices, "month")?.id,
            amount: findPricePerType(data?.plan?.prices, "month")?.amount,
            recurringIntervalCount: findPricePerType(data?.plan?.prices, "month")?.recurring
              ?.intervalCount,
            recurringInterval: findPricePerType(data?.plan?.prices, "month")?.recurring?.interval
          },
          {
            id: findPricePerType(data?.plan?.prices, "year")?.id,
            amount: findPricePerType(data?.plan?.prices, "year")?.amount,
            recurringIntervalCount: findPricePerType(data?.plan?.prices, "year")?.recurring
              ?.intervalCount,
            recurringInterval: findPricePerType(data?.plan?.prices, "year")?.recurring?.interval
          }
        ]);
      }
      formik.setFieldValue("name", data?.plan?.name);
      formik.setFieldValue("description", data?.plan?.description);
      formik.setFieldValue("orgId", data?.plan?.orgId);
      formik.setFieldValue("active", data?.plan?.active);
      setIsPublic(data?.plan?.orgId === "" ? true : false);
    }
  }, [data, data?.isEdit, isFreePlan]);

  useEffect(() => {
    if (organizations?.length === 0 && open) {
      dispatch(getOrgs({ query: "" }));
    }
    if (state?.id) {
      formik.setFieldValue("orgId", state.id);
      if (!data?.isEdit) {
        setIsPublic(false);
      }
    }
  }, [state, data]);
  useEffect(() => {
    if (!data?.isEdit) {
      formik.resetForm();
      setIsFreePlan(false);
    }
  }, [open]);
  return (
    <Drawer
      placement="right"
      onClose={() => {
        handleClose(id);
        formik.resetForm();
        setIsFreePlan(false);
      }}
      open={open}
      width="50%"
      closable={false}
      className="create-plan-drawer"
      destroyOnClose={true}
      maskClosable={false}
    >
      <div className="drawer-head">
        <img
          src={closeIcon}
          alt=""
          onClick={() => {
            handleClose(id);
            formik.resetForm();
            setIsFreePlan(false);
            setFiles([]);
          }}
        />
        <div className="title-drawer">
          <div>
            <p className="title">Create New Plan</p>
            <span className="subtitle">
              Add all the information in order to create your new plan
            </span>
          </div>
          {/* <span className="price-total">${formik.values.price}</span> */}
        </div>
      </div>
      <div className="content">
        <form action="">
          <div className="grid-plan-items">
            <InputText formik={formik} field={fields[0]} />
            <InputText formik={formik} field={fields[1]} />
            <div className="image-upload">
              <DraggerUpload
                formik={formik}
                field="images"
                isEdit={data?.isEdit}
                defaultFiles={data?.isEdit ? data?.plan?.images : []}
                files={files}
                setFiles={setFiles}
              />
            </div>
            <div className="plan-features">
              <PlanFeaturesForm formik={formik} cpu_p={cpu_p} ram_p={ram_p} />
            </div>
            <div className="grid-items-2">
              <div className="plan-visibility">
                <PlanVisibility formik={formik} isPublic={isPublic} setIsPublic={setIsPublic} />
              </div>
              <div className="allowed-templates">
                <AllowedTemplates formik={formik} />
              </div>
            </div>
            <Divider>
              <p className="divider-text">Prices</p>
            </Divider>

            <MonthlyYearlyPrice
              isFreePlan={isFreePlan}
              setIsFreePlan={setIsFreePlan}
              formik={formik}
            />
            <Divider />
          </div>
          <div className="actions">
            <Button
              className="btn-cancel"
              onClick={() => {
                handleClose(id);
                formik.resetForm();
                setFiles([]);
                setIsFreePlan(false);
              }}
            >
              Cancel
            </Button>
            <Button className="btn-publish" onClick={formik.handleSubmit}>
              Publish
            </Button>
          </div>
        </form>
      </div>
    </Drawer>
  );
}

export default CreatePlan;
