function SplashScreen() {
  return (
    <div className="splash">
      <article id="loader_wrapper">
        <section className="loader"></section>
        <section className="loader_section section_left"></section>
        <section className="loader_section section_right"></section>
      </article>
    </div>
  );
}

export default SplashScreen;
