import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import closeIcon from "../../../../assets/icons/dashboard-x-close.svg";
import { ReactComponent as UserAddedIcon } from "./../../assets/icons/user-added.svg";
import React from "react";
import { Button, Tooltip } from "antd";
import { UserAddOutlined } from "@ant-design/icons";

const GroupsInfo = {
  0: { color: "#05B7ED", label: "Already exist in Gomydesk" },
  1: { color: "#105624", background: "#88D49D", label: "New in Gomydesk" },
  2: { color: "#A40404", background: "#F8A7A7", label: "Already Invited" }
};

const InviteUserListModal = ({ id, open, handleClose, data, ...rest }) => {
  const getEmailGroup = (email) => {
    let group = null;
    Object.keys(data.response)?.forEach((groupKey, i) => {
      console.log(groupKey, i);
      if (data.response[groupKey]?.includes(email)) {
        group = i;
      }
    });
    return group !== null ? GroupsInfo[group] : null;
  };

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {}}
      className="ta-modal user_modal dashboard-user-modal invite-user-list"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px"
          }
        }
      }}
    >
      <DialogTitle id="alert-dialog-title" className="modal_title">
        <span className="label">
          <UserAddedIcon />
          <p>People added to organization</p>
          <div className="invite-user-info">
            You added <b> {data?.emails?.length} people</b> to your organization
          </div>
        </span>
        <span className="close-btn" onClick={() => handleClose(id)}>
          <img src={closeIcon} alt="" />
        </span>
      </DialogTitle>
      <DialogContent className="ta-modal-content-scroll">
        <div className="invite-user-list-content">
          {data?.emails?.map((email, i) => {
            const { color = "", label = "-", background } = getEmailGroup(email) || {};
            return (
              <div className="invited-user" key={i}>
                <UserAddOutlined />
                <Tooltip title={email}>
                  <p>{email}</p>
                </Tooltip>
                <div className="user-tag" style={{ background, color, borderColor: color }}>
                  {label}
                </div>
              </div>
            );
          })}
        </div>
        <div className="action_form">
          <Button type="primary" onClick={() => handleClose(id)}>
            OK
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InviteUserListModal;
