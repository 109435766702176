import { Drawer, Avatar, Tag } from "antd";
import closeIcon from "../../../../assets/icons/x-close.svg";
import Tabs from "../../../../components/Tabs/Tabs";
import { useSelector } from "react-redux";
import { changeUserDetailsTab } from "../../../../data/slices/tabsSlice";

function ImageDetails({ id, open, handleClose, data, ...rest }) {
  const { imageDetailsTabs } = useSelector((state) => state.tabs);
  const image = data?.image || null;

  const HandleTag = (values, defaultValue) => {
    return values?.map((el, index) => (
      <Tag color={el === defaultValue ? "#87d068" : "default"} key={index}>
        {el}
      </Tag>
    ));
  };

  let userDetails = {
    Name: image?.displayName,
    "Created At": image?.metadata?.creationTimestamp || "--",
    "Allow File Transfer": image?.proxy?.allowFileTransfer ? (
      <Tag color="green">Active</Tag>
    ) : (
      <Tag color="red">Inactive</Tag> || "--"
    ),

    Categories: HandleTag(image?.categories) || "--",
    CPU:
      HandleTag(image?.skuConfig?.cpu?.allowedValues, image?.skuConfig?.cpu?.defaultValue) || "--",
    RAM:
      HandleTag(image?.skuConfig?.ram?.allowedValues, image?.skuConfig?.ram?.defaultValue) || "--",
    Storage:
      HandleTag(
        image?.skuConfig?.storage?.allowedValues,
        image?.skuConfig?.storage?.defaultValue
      ) || "--"
  };

  const mappedObject = Object.keys(userDetails).map((key) => (
    <div className="key_value" key={key}>
      <p className="key">{key}</p>
      <p>{userDetails[key]}</p>
    </div>
  ));

  return (
    <Drawer placement="right" open={open} width="35%" closable={false} className="user_drawer">
      <div className="head_user_drawer">
        <div className="user_avatar">
          <Avatar size={50} src={image?.imageIconUrl} />
          <span>
            <p className="title">{image?.displayName || image?.name}</p>
          </span>
        </div>
        <div className="close_ion" onClick={() => handleClose(id)}>
          <img src={closeIcon}></img>
        </div>
      </div>
      {/* //content drawer  */}
      <div className="user_content">
        <Tabs
          tabs={imageDetailsTabs.tabs}
          defaultTab={imageDetailsTabs.activeTab}
          setTab={changeUserDetailsTab}
          type={imageDetailsTabs.type}
        />

        {imageDetailsTabs.activeTab === "0" ? (
          <div className="user_details">{mappedObject}</div>
        ) : (
          <div className="user_settings">user settings</div>
        )}
      </div>
    </Drawer>
  );
}

export default ImageDetails;
