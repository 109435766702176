import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { InputText } from "../../../../components/InputFields";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus-icon.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close-icon.svg";
import { ReactComponent as MailIcon } from "../../../../assets/icons/mail-01 (1).svg";
import { closeModal, openModal } from "../../../../data/slices/modals";
import { message } from "antd";
import { sendInvitations } from "../../../../data/slices/inviteUsers";
import { useLocation } from "react-router-dom";

function InviteUserForm() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const formik = useFormik({
    initialValues: {
      emails: [""],
      orgId: location.state.id
    },
    validationSchema: Yup.object().shape({
      emails: Yup.array().of(Yup.string().email().required("email is required"))
    }),
    onSubmit: (values) => {
      setLoading(true);
      dispatch(sendInvitations(values)).then((res) => {
        setLoading(false);
        if (!res.error) {
          dispatch(closeModal("invite-user-modal"));
          dispatch(openModal("invite-user-list-modal", { ...values, response: res.payload }));
        } else {
          message.error(res.error?.message || "There was an error sending the invitations.");
        }
      });
    }
  });
  const handleAddEmail = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!formik.values.emails.includes(""))
        formik.setFieldValue("emails", [...formik.values.emails, ""]);
    }
  };

  return (
    <div className="form_user invite-users-form">
      <FormikProvider value={formik}>
        <form action="" onSubmit={formik.handleSubmit} onKeyDown={handleAddEmail}>
          <label>Email address</label>
          <FieldArray
            name={"emails"}
            render={(arrayHelpers) => (
              <div className="email-input">
                <div className="email-input-container">
                  {formik.values?.["emails"]?.map((_, index) => {
                    const error =
                      formik.errors["emails"] && formik.touched["emails"]
                        ? formik.errors["emails"][index] && formik.touched["emails"][index]
                          ? formik.errors["emails"][index]
                          : null
                        : null;

                    return (
                      <div key={index}>
                        <div className={`input-container ${error ? "error" : ""}`}>
                          {index > 0 ? (
                            <div className="input-menu">
                              <CloseIcon onClick={() => arrayHelpers.remove(index)} />
                            </div>
                          ) : null}
                          <InputText
                            field={{
                              name: `emails.${index}`,
                              type: "string",
                              placeholder: "emails"
                            }}
                            value={formik.values?.["emails"][index]}
                            formik={formik}
                            prefix={<MailIcon />}
                            autoFocus={formik.values?.["emails"].length - 1 === index}
                          />
                        </div>
                      </div>
                    );
                  })}

                  <div className="plus-btn-container" onClick={() => arrayHelpers.push("")}>
                    <PlusIcon /> Add another
                  </div>
                </div>
              </div>
            )}
          />

          <div className="action_form">
            <button
              type="reset"
              onClick={() => {
                dispatch(closeModal("invite-user-modal"));
              }}
              className="dashboard-cancel-btn"
            >
              Cancel
            </button>
            <button type="submit" className="dashboard-confirm-btn">
              {loading ? "Loading..." : "Confirm"}
            </button>
          </div>
        </form>
      </FormikProvider>
    </div>
  );
}

export default InviteUserForm;
