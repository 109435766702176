import React from "react";
import { InputSelect, InputText } from "../../../../components/InputFields";
import "./_PlanFeaturesForm.scss";
import { stringBoolean } from "../../../../helpers/stringBoolean";
import ldapIcon from "../../assets/icons/ldap.svg";
import usersIcon from "../../assets/icons/users.svg";
import storageIcon from "../../assets/icons/storage.svg";
import SliderValues from "../Slider/Slider";
import { CPU_MARKS, DISK_MARKS, RAM_MARKS, GPU_MARKS } from "../../constants";
import cpuIcon from "../../assets/icons/cpu.svg";
import ramIcon from "../../assets/icons/ram.svg";
import diskIcon from "../../assets/icons/disk.svg";
import BooleanFeature from "../BooleanFeature/BooleanFeature";

function PlanFeaturesForm({ formik, cpu_p, ram_p }) {
  const calcRamPrice = () => {
    if (formik.values.ramFeature.number > 0) {
      return (formik.values.ramFeature?.number * ram_p).toFixed(3);
    } else {
      return 0;
    }
  };

  const calcCpuPrice = () => {
    if (formik.values.cpuFeature.number > 0) {
      return (formik.values.cpuFeature.number * cpu_p).toFixed(3);
    } else {
      return 0;
    }
  };

  const calcGpuPrice = () => {
    if (formik.values.cpuFeature.number > 0) {
      return (formik.values.cpuFeature.number * cpu_p).toFixed(3);
    } else {
      return 0;
    }
  };
  return (
    <div className="plan_features_form">
      <div className="grid_line">
        <div className="grid-features-form">
          <div className="cpu-slider">
            <div className="input_s">
              <SliderValues
                // label={`Cpu / Hours (${cpu_p})`}
                label="Number of Cores (CPU)"
                onChange={(cpu) => formik.setFieldValue("cpuFeature.number", cpu)}
                marks={CPU_MARKS}
                value={formik.values.cpuFeature.number}
                max={12}
                min={1}
                labelIcon={cpuIcon}
                disabled={formik.values.cpuFeature.number > 12}
              />
              <InputText
                formik={formik}
                pattern="[0-9]*"
                field={{
                  name: "cpuFeature.number",
                  type: "number"
                }}
                value={formik.values.cpuFeature?.number}
                status={
                  formik.errors.cpuFeature?.number && formik.touched.cpuFeature?.number
                    ? "error"
                    : null
                }
              />
              <div className="price_item">
                <span className="unit">Price/ unit</span>
                <input className="price" value={calcCpuPrice()}></input>
              </div>
            </div>
            <p className="error_txt">
              {formik.touched?.cpuFeature?.number && formik.errors?.cpuFeature?.number}
            </p>
          </div>
          <div className="gpu-slider">
            <div className="input_s">
              <SliderValues
                // label={`Cpu / Hours (${cpu_p})`}
                label="Number of Cards (GPU)"
                onChange={(gpu) => formik.setFieldValue("gpuFeature.number", gpu)}
                marks={GPU_MARKS}
                value={formik.values.gpuFeature.number}
                max={4}
                min={1}
                labelIcon={cpuIcon}
                disabled={formik.values.gpuFeature.number > 4}
              />
              <InputText
                formik={formik}
                pattern="[0-9]*"
                field={{
                  name: "gpuFeature.number",
                  type: "number"
                }}
                value={formik.values.gpuFeature?.number}
                status={
                  formik.errors.gpuFeature?.number && formik.touched.gpuFeature?.number
                    ? "error"
                    : null
                }
              />
              <div className="price_item">
                <span className="unit">Price/ unit</span>
                <input className="price" value={calcGpuPrice()}></input>
              </div>
            </div>
            <p className="error_txt">
              {formik.touched?.gpuFeature?.number && formik.errors?.gpuFeature?.number}
            </p>
          </div>
          <div className="ram-slider">
            <div className="input_s">
              <SliderValues
                // label={`RAM / Hours (${ram_p})`}
                label="Ram Size (GB)"
                name="ramFeature?.number"
                value={formik.values.ramFeature?.number}
                onChange={(ram) => formik.setFieldValue("ramFeature.number", ram)}
                marks={RAM_MARKS}
                max={32}
                min={1}
                labelIcon={ramIcon}
              />
              <InputText
                formik={formik}
                field={{
                  name: "ramFeature.number",
                  type: "number"
                }}
                value={formik.values.ramFeature?.number}
                status={
                  formik.errors.ramFeature?.number && formik.touched.ramFeature?.number
                    ? "error"
                    : null
                }
              />
              <div className="price_item">
                <span className="unit">Price/ unit</span>
                <input className="price" value={calcRamPrice()}></input>
              </div>
            </div>
            <p className="error_txt">
              {formik.touched?.ramFeature?.number && formik.errors?.ramFeature?.number}
            </p>
          </div>
          <div className="disk-slider">
            <div className="input_s">
              <SliderValues
                label={`Disk Size (GB)`}
                name="storageFeature.number"
                value={formik.values.storageFeature?.number}
                onChange={(s) => formik.setFieldValue("storageFeature.number", s)}
                marks={DISK_MARKS}
                max={60}
                min={1}
                labelIcon={diskIcon}
              />
              <InputText
                formik={formik}
                field={{
                  name: "storageFeature.number",
                  type: "number"
                }}
                value={formik.values.storageFeature.number}
                status={
                  formik.errors.storageFeature?.number && formik.touched.storageFeature?.number
                    ? "error"
                    : null
                }
              />
            </div>
            <p className="error_txt">
              {formik.touched?.storageFeature?.number && formik.errors?.storageFeature?.number}
            </p>
          </div>

          <div className="ldap_checkbox num-user">
            <div className="title_item">
              <img src={usersIcon} alt="" />
              <p> Number of user</p>
            </div>
            <div className="user-input">
              <InputText
                formik={formik}
                field={{
                  name: "numberOfUsersFeature.number",
                  type: "number"
                }}
                value={formik.values.numberOfUsersFeature.number}
                status={
                  formik.errors.numberOfUsersFeature?.number &&
                  formik.touched.numberOfUsersFeature?.number
                    ? "error"
                    : null
                }
              />
            </div>
          </div>

          <BooleanFeature
            title="LDAP Connection"
            icon={ldapIcon}
            checked={stringBoolean(formik.values.ldapFeature?.isOn)}
            onChange={(e) => formik.setFieldValue("ldapFeature.isOn", e)}
          />
          <BooleanFeature
            title="Persistent Storage"
            icon={storageIcon}
            checked={stringBoolean(formik.values.persistentStorageFeature?.isOn)}
            onChange={(e) => formik.setFieldValue("persistentStorageFeature.isOn", e)}
          />
          <BooleanFeature
            title="Quota Management"
            icon={storageIcon}
            checked={stringBoolean(formik.values.quotaManagementFeature?.isOn)}
            onChange={(e) => formik.setFieldValue("quotaManagementFeature.isOn", e)}
          />
          <BooleanFeature
            title="Snapshot"
            icon={storageIcon}
            checked={stringBoolean(formik.values.snapshotFeature?.isOn)}
            onChange={(e) => formik.setFieldValue("snapshotFeature.isOn", e)}
          />
          <BooleanFeature
            title="Sku Configuration"
            icon={storageIcon}
            checked={stringBoolean(formik.values.skuConfigurationFeature?.isOn)}
            onChange={(e) => formik.setFieldValue("skuConfigurationFeature.isOn", e)}
          />
          <BooleanFeature
            title="Traffic Filtering"
            icon={storageIcon}
            checked={stringBoolean(formik.values.trafficFilteringFeature?.isOn)}
            onChange={(e) => formik.setFieldValue("trafficFilteringFeature.isOn", e)}
          />
          <span className="org_item support-type">
            <InputSelect
              label="Support Type"
              placeholder="Support Type"
              single={true}
              formik={formik}
              options={[
                { label: "Basic", value: "Basic" },
                { label: "Pro", value: "Pro" },
                { label: "Enterprise", value: "Enterprise" }
              ]}
              defaultOptions={formik?.values?.supportFeature?.value || null}
              value={formik?.values?.supportFeature?.value}
              onChange={(e) => {
                formik.setFieldValue("supportFeature.value", e);
              }}
              status={
                formik.errors?.supportFeature && formik.touched?.supportFeature ? "error" : null
              }
              field={`supportFeature`}
            />
            <p className="error_txt" style={{ marginTop: "-16px" }}>
              {formik.errors?.supportFeature && formik.touched?.supportFeature
                ? formik.errors?.supportFeature?.value
                : null}
            </p>
          </span>
        </div>
      </div>
    </div>
  );
}

export default PlanFeaturesForm;
