import { configureStore } from "@reduxjs/toolkit";
import exampleReducer from "./slices/exampleSlice";
import authReducer from "./slices/authSlice";
import { reducer as modalsReducer } from "./slices/modals";
import settingsReducer from "./slices/settingsSlice";
import templatesReducer from "./slices/templates";
import categoriesReducer from "./slices/categoriesTemplates";
import sessionsReducer from "./slices/sessions";
import memberReducer from "./slices/memberSlice";
import tabsReducer from "./slices/tabsSlice";
import usersGroupReducer from "./slices/userGroup";
import imageGroupsReducer from "./slices/imageGroup";
import sortReducer from "./slices/sortSlice";
import billingReducer from "./slices/billingSlice";
import orgReducer from "./slices/organizations";
import usersReducer from "./slices/usersPerOrg";
import pricingReducer from "./slices/plans";
import inviteReducer from "./slices/inviteUsers";
import statsReducer from "./slices/statisticSlice";
import pendingUserReducer from "./slices/pendingUsersSlice";
import resourcesVerbsReducer from "./slices/resourcesSlice";

export const store = configureStore({
  reducer: {
    example: exampleReducer,
    auth: authReducer,
    modals: modalsReducer,
    settings: settingsReducer,
    templates: templatesReducer,
    categories: categoriesReducer,
    sessions: sessionsReducer,
    member: memberReducer,
    tabs: tabsReducer,
    usersGroup: usersGroupReducer,
    imageGroups: imageGroupsReducer,
    sort: sortReducer,
    billing: billingReducer,
    organization: orgReducer,
    users: usersReducer,
    pricing: pricingReducer,
    invite: inviteReducer,
    stats: statsReducer,
    pending: pendingUserReducer,
    resources_verbs: resourcesVerbsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
